#MessageHouseContent .fileTemplate {
    color: #007E7A;
}

#modalNewMessage.e-dialog {
    min-height: 55%;
}

#modalNewMessage {
    max-height: calc(100% - 50px) !important;
}

#modalNewMessage > div:first-child button {
    position: absolute;
    right: -15px;
    left: inherit;
    top: -15px;
}

.labelModal.delete {
    color: #BB133E;
}

.phraseDelete {
    margin-left: 30px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    min-width: 322px;
    text-align: initial;
}

#delete {
    color: #BB133E;
    font-weight: 700;
    font-family: 'Caecilia';
    font-size: 18px;
}

 .phraseDelete .labelModal {
    font-family: 'Caecilia';
    font-weight: 600;
    font-size: 18px;
}

 .phraseDelete .labelModal.approve {
    color: #69BE28;
}

.phraseDelete .labelModal.delete {
    color: #BB133E;
}


#modalNewMessage .e-btn.e-flat.e-primary {
    width: 197px;
    color: #ffffff;
    background-color: #007e7a;
}

#modalNewMessage .input label {
    margin: 0px;
}

#modalNewMessage label:not(.e-error) {
    font-size: 14px;
    line-height: 21px;
    font-family: 'MyriadPro', sans-serif;
    color: #747876;
    font-weight: 600;
}

#modalNewMessage .e-input-group.e-control-wrapper.e-valid-input {
    color: #747876;
    font-size: 14px;
    line-height: 17px;
    font-family: "MyriadPro" !important;
    opacity: 0.8;
}

#modalNewMessage .input {
    margin: 21px 0 4px;
    width: 100%;
}

#modalNewMessage .upload {
    border: 1px solid #F1F1F1;
    border-radius: 4px;
    margin-top: 10px;
    padding: 9px 8px;
}

#modalNewMessage .upload > div {
    border: 1px dotted #D8CDCD;
    border-radius: 4px;
}

#modalNewMessage .upload > div.new {
    background-color: #F7F9F9;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#modalNewMessage .upload > div img {
    width: 100%;
}

#modalNewMessage .upload > div p {
    color: #747876;
    font-size: 16px;
    line-height: 19px;
    font-family: 'MyriadPro';
    font-weight: 400;
    margin: 17px 0;
}

#modalNewMessage .upload > div p span {
    cursor: pointer;
    color: #007E7A;
}

#modalNewMessage .upload > div span#change {
    cursor: pointer;
    display: block;
}

#modalNewMessage .e-file-select-wrap {
    display: none;
}

#modalNewMessage .e-upload .e-upload-files {
    background-color: #F7F9F9;
    border: 1px solid #F1F1F1;
}

#modalNewMessage .e-upload {
    border: none;
}