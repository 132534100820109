.actions {
    background-color: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    margin-bottom: 30px;
}

.actions.profile {
    margin-top: -16px;
}

.actions .top {
    padding: 18px;
    border-bottom: 2px solid #F7F9F9;
}

.actions .top .mdl2 {
    color: #007E7A;
    font-size: 18px;
}

.actions .top h2 {
    color: #007E7A;
    font-family: 'Caecilia';
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 15px;
}

.actions .top button {
    margin: 0;
    font-size: 14px;
    line-height: 15px;
    font-family: 'MyriadPro' !important;
    text-transform: none;
    font-weight: 400;
}

.actions .highlight {
    display: flex;
    padding: 8.5px 18px;
    flex-direction: column;
    justify-content: center;
    background-color: #F7F9F9;
    border-bottom: 1px solid #ECECEC;
}

.actions .highlight button {
    margin: 0;
    font-size: 14px;
    line-height: 15px;
    font-family: 'MyriadPro' !important;
    font-weight: 400;
    background-color: #fff !important;
}

.actions .content {
    padding: 20px 18px 13px
}

.actions .content button {
    margin: 0;
    font-size: 13px;
    line-height: 15px;
    font-family: 'MyriadPro' !important;
    font-weight: 400;
    margin-bottom: 4%;
}

/*Importar*/

/*Exportar*/
.btnExport {
    display: inline-block;
}

.buttons .btnExport button {
    background-color: transparent;
    box-shadow: none;
    color: #747876;
    border: 2px solid;
    border-color: #EDB111;
    margin: 0 15px 0 0;
    width: 127px;
    height: 28px;
}

/*Novo*/
.btnNew {
    display: inline-block;
}

.btnNew button {
    background-color: #edb111!important;
    border-color: transparent;
    color: #fff;
    width: 127px;
    height: 28px;
}

/*Voltar*/

/*Fim Buttons*/

/*Export*/

/* Remove margin bottom from export report*/
#exportReport {
  margin-bottom: 0;
}

.export {
    background-color: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    margin-bottom: 30px;
}

.export .top {
    padding: 18px;
    border-bottom: 2px solid #F7F9F9;
}

.export .top .mdl2 {
    color: #007E7A;
    font-size: 18px;
}

.export .top h2 {
    color: #007E7A;
    font-family: 'Caecilia';
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 15px;
}

.export .content {
    padding: 20px 18px 13px;
}

.export .content button {
    margin-bottom: 15px;
}

.export .content .e-btn.e-flat.e-primary,
.export .content .e-css.e-btn.e-flat.e-primary {
    background-color: #F7F9F9;
}

.export .content button .mdl2 {
    margin-right: 5px;
}

/*Fim Export*/

/*Breadcrumbs*/

.breadcrumbs {
    width: 100%;
    padding: 15px 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 1305px;
    display: flex;
    align-items: center;
}

.breadcrumbs a,
.breadcrumbs label {
    color: #747876;
    font-family: 'MyriadPro';
    font-size: 13px;
    margin: 0;
}

.breadcrumbs .mdl2.mdl2-arrowRightBold {
    font-size: 8px;
    padding: 0 5px;
    color: #EDB111;
}
