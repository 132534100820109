#modalProfile.preload .e-upload-file-list{
    min-height: 45px;
}
#modalProfile.preload .e-file-size, #modalProfile.preload .e-file-status{
    display: none;
}

#modalProfile > div:first-child button {
    position: absolute;
    right: -21px;
    left: inherit;
    top: -41px;
    margin: 25px 5px 20px 20px;
}

#modalProfile .upload {
    border: 1px solid #F1F1F1;
    border-radius: 4px;
    padding: 9px 8px;
}

#modalProfile .upload > div {
    border: 1px dotted #D8CDCD;
    border-radius: 4px;
}

#modalProfile .upload > div.new {
    background-color: #F7F9F9;
    text-align: center;
    height: 245px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#modalProfile .upload > div img {
    width: 100%;
}

#modalProfile .upload > div p {
    color: #747876;
    font-size: 16px;
    line-height: 19px;
    font-family: 'MyriadPro';
    font-weight: 400;
    margin: 35px 0 0;
}

#modalProfile .upload > div p span {
    cursor: pointer;
    color: #007E7A;
}

#modalProfile .upload > div span#change {
    cursor: pointer;
    display: block;
}

#modalProfile .e-upload {
    border: none;
}

#modalProfile .e-upload .e-file-select-wrap {
    display: none;
}

#modalProfile .e-upload .e-upload-files {
    background-color: #F7F9F9;
    border: 1px solid #F1F1F1;
}

#modalProfile .e-btn.e-flat.e-primary {
    width: 197px;
    color: #ffffff;
    background-color: #007e7a;
    margin: 25px 5px 20px 20px;
}

#modalProfile #loadingPhoto {
    height: 80px;
    position: relative;
}

 #modalProfile #validateImage {
    color: #ff0000;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
    font-size: 12px;
    font-weight: normal;
    padding-top: 4px;
}

#modalProfile .e-upload {
    display: none;
}

#modalProfile .custom-e-upload {
    display: block !important;
}