.videoCards {
    margin-top: -24px;
    margin-bottom: 80px;
}

.videoCards .secondVideoCards {
    background-color: #fff;
    border-bottom: 2px solid #E4F2F2;
}

.videoTitle {
    font-family: 'Caecilia';
    color: #007E7A;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    padding: 30px 20px;
    margin-top: 55px;
    width: 100%;
}

.videosItems {
    display: flex;
    flex-direction: column;
    width: calc(100% / 4 - 30px);
    margin: 0 15px 30px;
}

.videosItems .subtitleImage {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
}

.videosItems .subtitleImage .arrowDiv {
    width: 19px;
    height: 42px;
    background-color: #F7F9F9;
    border: none;
    color: #9C9C9C;
}

.videosItems .subtitleImage .arrowDiv.mdl2.mdl2-arrowUp {
    font-size: 10px;
    display: flex;
    align-items: center;
    padding: 0px 5px;
    transition: all 0.5s;
}

.videosItems .subtitleImage .opened::before {
    transition: all 0.5s;
}

.videosItems .subtitleImage .closed::before {
    transform: rotate(180deg);
    transition: all 0.5s;
}

.videosItems .subtitleImage .arrowDiv:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
}

.videosItems .videoInfo {
    position: relative;
}

.videosItems .videoContent {
    border-bottom: 5px solid #ECECEC;
}

.videosItems .videoInfo > img {
    width: 100%;
    margin-bottom: 13px;
    cursor: pointer;
}

.videosItems .subtitleImage .subtitle {
    color: #747876;
    font-size: 16px;
    font-family: 'Caecilia';
    font-weight: 600;
    margin-bottom: 13px;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    height: 50px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.innerContent {
    display: flex;
    border-top: 1px solid #ECECEC;
    align-items: center;
    justify-content: space-between;
}

.innerContent img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.innerContent > div label {
   font-size: 13px;
   font-weight: 600;
   color: #747876;
   margin: 0;
   font-family: 'MyriadPro', sans-serif;
}

.innerContent > div span {
   font-size: 14px;
   line-height: 17px;
   font-weight: 500;
   color: #747876;
   font-family: 'MyriadPro', sans-serif;
}

.innerContent > div {
    display: flex;
    flex-direction: column;
    margin: 7px 35px 7px 0;
}

.innerContent .label {
    font-size: 13px;
    padding: 7px 18px;
    font-weight: 500;
    margin: 0;
    border-radius: 2px;
    font-family: 'MyriadPro', sans-serif;
}

.innerContent .label.approvedLabel {
    color: #6CB535;
    background-color: #f5f8f3;
}

.innerContent .label.waitingLabel {
    color: #EE7518;
    background-color: #fffaf6;
}

.innerContent .label.disapprovedLabel {
    color: #BB133E;
    background-color: #fdf7f6;
}

.innerApprovals {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

#innerContentModal > div {
    margin: 10px;
}

#innerContentModal {
    justify-content: flex-start;
    border-top: 0;
    padding-bottom: 10px;
}

#modalVideos {
    max-height: calc(100% - 50px)!important;
}

.videosItems .dropContent {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ECECEC;
    padding-top: 15px;
}

.videosItems .dropContent .leftContent label{
    font-family: 'MyriadPro', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #747876;
    padding: 4px 8px;
}

.videosItems .dropContent .rightContent label{
    font-family: 'MyriadPro', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #8f9391;
    padding: 4px 8px;
    background-color: #F7F9F9;
    width: fit-content;
    margin-left: auto;
}

.videosItems .dropContent .leftContent {
    display: flex;
    flex-direction: column;
}

.videosItems .dropContent .rightContent {
    display: flex;
    flex-direction: column;
    text-align: right;
}

.videosItems .bottomActions {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-top: 1px solid #ECECEC;
}

 .videosItems .bottomActions .buttons {
    font-family: 'MyriadPro', sans-serif;
    font-size: 13px;
    line-height: 15px;
    padding: 7px 22px;
    border-radius: 2px;
    background-color: #fff;
 }

.videosItems .bottomActions .buttons.approve {
    color: #69BE28;
    border: 1px solid #69BE28;
}

.videosItems .bottomActions .buttons:focus {
    outline: 0;
}

.videosItems .bottomActions .buttons.disapprove {
    color: #BB133E;
    border: none;
}

.waitingApproval,
.approved {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    width: 100%;
}

.mainWaitingDiv {
    background-color: #fff;
    width: 100%;
    margin-top: -55px;
}

.loading {
    margin: 50px auto;
    display: flex;
    position: relative;
    justify-content: center;
}

.loadMoreDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    position: relative;
}

.loadMoreDiv:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #E4F2F2;
}

.loadMoreDiv button {
    color: #007E7A;
    padding: 7px 22px;
    border: none;
    z-index: 1;
    font-size: 13px;
    line-height: 15px;
    font-family: 'MyriadPro';
    margin-bottom: -10px;
    background-color: #FFFFFF;
}

.mainWaitingDiv .loadMoreDiv button {
    background-color: #f7f9f9;
}

.videoContentBox {
    margin-bottom: 100px;
    width: 100%;
}

.loadMoreDiv button:focus{
    outline: 0;
}

/*--------Modal Video CSS ---------*/

#modalVideos.e-dialog {
    min-height: 90%;
    margin: 20px;
}

#modalVideos > div:first-child button {
    position: absolute;
    right: -15px;
    left: inherit;
    top: -15px;
}

#modalVideos .upload {
    border: 1px solid #F1F1F1;
    border-radius: 4px;
    padding: 9px 8px;
    position: relative;
}

#modalVideos .upload.error {
    border: 1px solid #D20340;
}

#modalVideos .upload > div {
    border: 1px dotted #D8CDCD;
    border-radius: 4px;
}

#modalVideos .upload > div.new {
    background-color: #F7F9F9;
    text-align: center;
    height: 245px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#modalVideos .upload > div img {
    width: 100%;
}

#modalVideos .upload > div p {
    color: #747876;
    font-size: 16px;
    line-height: 19px;
    font-family: 'MyriadPro';
    font-weight: 400;
    margin: 35px 0 0;
}

#modalVideos .upload > div p span {
    cursor: pointer;
    color: #007E7A;
}

#modalVideos .upload > div span#change {
    cursor: pointer;
    display: block;
}

#innerApprovals {
    margin-top: 22px;
}

#modalVideos .input {
    margin-top: 22px;
    min-height: 91px;
}

#modalVideos label:not(.e-error) {
    font-size: 14px;
    line-height: 17px;
    line-height: 21px;
    font-family: 'MyriadPro', sans-serif;
    color: #747876;
    font-weight: 600;
    margin-bottom: 0;
    font-weight: 500;
}

#innerApprovals #innerContentModal > div label {
    font-weight: 600;
}

#modalVideos .e-upload {
    border: none;
}

#modalVideos .e-upload .e-file-select-wrap {
    display: none;
}

#modalVideos .e-upload .e-upload-files {
    background-color: #F7F9F9;
    border: 1px solid #F1F1F1;
}

#modalVideos .e-footer-content .e-control.e-btn.e-lib.e-flat {
    width: 197px;
    color: #ffffff;
    background-color: #007e7a;
}

#modalVideos .bannerDelete {
    background-color: #F7F9F9 !important;
    color: #BB133E !important;
    float: left;
}

#modalVideos #validateImage {
    color: #ff0000;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
    font-size: 12px;
    font-weight: normal;
    padding-top: 4px;
}

#modalVideos .input albel {
    margin: 0px;
}

#modalVideos .inputsModal {
    display: flex;
    flex-direction: row;
}

#modalVideos .inputsModal .leftInputs {
    margin-right: 8px;
    width: 100%;
}

#modalVideos .inputsModal .rightInputs {
    margin-left: 8px;
    width: 100%;
}

#modalVideos .labelModal {
    font-weight: 700;
    font-family: 'Caecilia';
    font-size: 18px;
}

#modalVideos .labelModal.approve {
    color: #69BE28;
}

#modalVideos .e-input-group.error {
    border-color: #D20340;
}

#modalVideos .error {
    border-color: #D20340;
    color: #D20340;
    margin-top: 0;
}

#modalVideos .labelModal.delete {
    color: #BB133E;
}

#modalVideos .hidden {
    display: none;
}
#modalVideos .detailsDiv {
    display: flex;
    background-color: #F7F9F9;
    justify-content: space-between;
    padding: 13px 19px;
    align-items: flex-end;
}

#modalVideos .detailsDiv label {
    font-family: 'MyriadPro';
    color: #747876;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
}

#modalVideos .detailsDiv span {
    font-family: 'MyriadPro';
    color: #747876;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.75;
}

#modalVideos .detailsDiv > div {
    display: flex;
    flex-direction: column;
}

#modalVideos .detailsDiv .link {
    color: #007E7A;
    cursor: pointer;
}

#modalVideos .detailsDiv button {
    border: 1px solid #007E7A;
    border-radius: 2px;
    padding: 6px 11px;
    color: #007E7A;
    font-family: 'MyriadPro';
    font-size: 13px;
    background-color: #fff;
    height: 28px;
}

.playerVideo {
    position: absolute;
    width: 100px;
    height: 100px;
    border: 3px solid #fff;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    background: #00000066 0% 0% no-repeat padding-box;
}

#modalVideos .playerVideo {
    width: 126px;
    height: 126px;
}

#modalVideos .thumbGenerated-message{
    display: block;
    padding: 10px 10px 10px 60px;
    border-radius: 2px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
    background: #007E7A;
    margin: 10px 0;
    color: #FFFFFF;
    position: relative;
}

#modalVideos .thumbGenerated-message-error {
    background: #bb133e;
}

#modalVideos .thumbGenerated-message-error:before {
    content: "\e711" !important;
    top: 6px;
}

#modalVideos .thumbGenerated-message:before {
    content: "\e8fb";
    font-family: 'Fabric External MDL2 Assets';
    font-style: normal;
    font-size: 22px;
    text-rendering: auto;
    position: absolute;
    top: 5px;
    left: 13px;
}

#modalVideos .thumbGenerated-message:after {
    content: "";
    position: absolute;
    width: 3.5em;
    top: 0;
    left: 0;
    bottom: 0;
    right: auto;
    background-color: black;
    opacity: 0.2;
}
#modalVideos .messageFileSize{
    display: block;
    font-size: 14px;
    line-height: 17px;
    margin-top: 4px;
    color: #747876 !important;
    font-style: italic;
}

.generateThumb {
    width: 150px;
    height: 28px;
    color: #fff;
    background-color: #007E7A;
    font-family: 'MyriadPro';
    font-size: 13px;
    font-weight: 400;
    border: none;
    position: absolute;
    right: 25px;
    /* bottom: 33px; */
    bottom: 75px;
}

.playerVideo::before {
    content: "";
    position: absolute;
    border: 25px solid transparent;
    border-left: 40px solid #fff;
    top: 50%;
    left: calc(50% + 20px);
    transform: translate(-50%, -50%);
}

/* -------------- Modal de Aprovação e Reprovação --------------- */

#approveModal,
#disapproveModal {
    width: 446px;
    height: 340px;
}

.changesModal .divIcon .phraseDelete {
    margin-left: 30px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    min-width: 322px;
    text-align: initial;
}

.changesModal #delete {
    color: #BB133E;
    font-weight: 700;
    font-family: 'Caecilia';
    font-size: 18px;
}

.changesModal #labelDelete {
    font-family: 'Caecilia';
    font-size: 14px;
    line-height: 17px;
    color: #747876;
}

.changesModal hr {
    margin: 15px 0 0;
}

.changesModal .divIcon {
    background-color: #E9F2DA;
    display: flex;
    border-radius: 50%;
    width: 55px;
    text-align: center;
    height: 55px;
    margin: 18px 18px 0;
}

.changesModal .divIcon.Disapprove {
    background-color: #F6D6D2;
}

.changesModal .divIcon .mdl2.mdl2-confirm {
    color: #69BE28;
    font-size: 38px;
    padding: 8px 0 0 10px;
}

.changesModal .divIcon .mdl2.mdl2-disapprove {
    color: #BB133E;
    font-size: 38px;
    padding: 8px 0 0 10px;
}

.changesModal .divIcon .phraseDelete .labelModal {
    font-family: 'Caecilia';
    font-weight: 600;
    font-size: 18px;
}

.changesModal .divIcon .phraseDelete .labelModal.approve {
    color: #69BE28;
}

.changesModal .divIcon .phraseDelete .labelModal.delete {
    color: #BB133E;
}

.changesModal .e-dlg-content {
    padding: 0;
}

.changesModal .e-footer-content {
    display: flex;
    justify-content: space-around;
    padding: 19px 0;
}

.changesModal .e-footer-content button {
    width: 198px;
    height: 28px;
    border: none;
}

.changesModal .e-footer-content button:first-child {
    background-color: #F7F9F9;
    color: #747876; 
}

.changesModal .e-footer-content button:last-child {
    background-color: #007E7A;
    color: #fff;
}

#disapproveModal .e-footer-content button:last-child {
    background-color: #BB133E;
    color: #FFFFFF;
}

.changesModal .e-footer-content .buttonDisapprove {
    background-color: red;
}

.changesModal .content {
    display: flex;
    flex-direction: column;
    padding: 21px;
}

.changesModal .content span {
    font-family: 'MyriadPro';
    font-size: 14px;
    line-height: 17px;
    color: #747876;
    font-weight: 500;
}

.changesModal .content label {
    font-family: 'MyriadPro';
    font-size: 14px;
    line-height: 17px;
    color: #747876;
    opacity: 0.75;
    margin-top: 25px;
}

.changesModal .content textarea {
    margin-top: 20px;
}

#modalVideos .videoContainer {
 position: relative;   
}

.container-fluid.full {
    max-width: 100%;
}

.notFound {
    font-family: 'Caecilia';
    color: #007E7A;
    font-size: 16px;
    line-height: 20px;
    padding: 0 15px;
    width: 100%;
}

@media screen and (max-width: 1024px) {
    .videosItems {
        width: calc(100% / 3 - 30px);
    }
}

@media screen and (max-width: 768px) {
    .videosItems {
        width: calc(100% / 2 - 30px);
    }
}

@media screen and (max-width: 500px) {
    .videosItems {
        width: calc(100% - 30px);
    }
    #modalVideos .inputsModal {
        flex-direction: column;
    }
    #modalVideos .inputsModal .leftInputs,
    #modalVideos .inputsModal .rightInputs {
        margin: 0;
    }
    #modalVideos .detailsDiv {
        flex-wrap: wrap;
    }
    #modalVideos .detailsDiv > div {
        margin-top: 10px;
    }
    #modalVideos .detailsDiv > div:first-child {
        width: 100%;
    }
    #modalVideos .detailsDiv button {
        margin-top: 20px;
        margin-left: auto;
    }
    #modalVideos .e-footer-content .e-control.e-btn.e-lib.e-flat {
        width: 50%;
        margin: 0;
    }
}

#loadingItem .e-spinner-pane.e-spin-show {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: #dedede80;
}

.hidden {
    display: none !important;
}

.disapprove.e-footer-content,
.approve.e-footer-content {
    display: flex !important;
    flex-flow: row;
    position: absolute;
    bottom: 0 !important;
}

.disapprove.e-footer-content .e-primary,
.approve.e-footer-content .e-primary {
    flex: 1;
}