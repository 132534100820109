.login {
    max-width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: calc(100vh - 58px);
    position: relative;
}

.login.enter {
    background-image: url('../../css/imgs/bg_login_enter.png');
}

.login.forgot {
    background-image: url('../../css/imgs/bg_login_forgot.png');
}

.login.reset {
    background-image: url('../../css/imgs/bg_login_reset.png');
}

@media screen and (min-width: 1475px) {
    .container.login {
        background-size: 100%;
    }
}

.login .boxLogin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #ECECEC;
    width: 80%;
    max-width: 446px;
}

.login .boxLogin .topo {
    padding: 23px 23px 23px 17px;
    position: relative;
}

.login .boxLogin .topo .systemName {
    color: #747876;
    font-size: 17px;
    line-height: 21px;
    font-family: "Caecilia";
    font-weight: 700;
    padding-right: 70px;
}

.login .boxLogin .topo .systemName span {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}

.login .boxLogin .topo .systemName span:first-child {
    display: none;
}

.login .boxLogin .topo .systemName span:last-child {
    display: block;
}

@media screen and (max-width: 530px) {
    .login .boxLogin .topo .systemName span:first-child {
        display: block;
    }

    .login .boxLogin .topo .systemName span:last-child {
        display: none;
    }
}

.login .boxLogin .topo svg {
    max-width: 60px;
    position: absolute;
    right: 23px;
    top: 21px;
}

.login .boxLogin .meio {
    padding: 23px 23px 0 17px;
    position: relative;
}

.login .boxLogin .meio h1 {
    color: #007E7A;
    font-size: 28px;
    line-height: 33px;
    font-family: "Caecilia";
    font-weight: 300;
}

.login .boxLogin .meio h2 {
    color: #747876;
    font-size: 18px;
    line-height: 22px;
    font-family: "Caecilia";
    font-weight: 400;
}

.login .boxLogin .meio form {
    border-top: 1px solid #ECECEC;
    margin: 22px 0 0;
}

.login .boxLogin .meio form label {
    font-size: 14px;
    line-height: 21px;
    color: #747876;
    font-family: "MyriadPro";
    font-weight: 700;
    margin: 20px 0 12px;
}


.login .boxLogin .meio form input[type=email] {
    text-transform: lowercase;
}

.login .boxLogin .meio form input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #F7F9F9 inset;
    -webkit-text-fill-color: #747876 !important;
}

.login .boxLogin .meio form input#email,
.login .boxLogin .meio form input#password {
    font-size: 14px;
    line-height: 17px;
    color: rgba(116, 120, 118, 0.75);
    font-family: 'MyriadPro' !important;
    font-weight: 400;
}

.login .boxLogin .meio form .e-input-focus input:placeholder {
    color: rgba(116, 120, 118, 0.75);
    }

.login .boxLogin .msgs {
    padding: 0 23px 23px 17px;
    font-size: 14px;
    line-height: 18px;
    /* color: #BB133E; */
    color: #d20340;
    text-align: center;
    font-family: MyriadPro;
}

.login .boxLogin .botoes {
    margin-top: 22px;
    padding: 0 23px 23px 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.login .boxLogin .botoes.right {
    flex-direction: row-reverse
}

.login .boxLogin .botoes button {
    padding: 15px 46px;
}

.login .boxLogin .botoes button.processing {
    background-color: #007E7A;
}

.login .boxLogin .botoes button#forgotPassword {
    color: #007E7A;
    font-size: 14px;
    line-height: 17px;
    font-family: 'MyriadPro' !important;
    font-weight: 400;
    text-transform: initial;
}

.login .boxLogin .botoes button span.e-btn-content {
    font-family: 'MyriadPro';
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    text-transform: initial;
}

.login .boxLogin .msgSuccess {
    color: #69BE28;
}

@media screen and (max-width: 768px) {
    .login .boxLogin .botoes {
        flex-direction: column;
    }
    .login .boxLogin .botoes button {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .login .boxLogin .meio h1 {
        font-size: 24px;
    }
    .login .boxLogin .meio h2 {
        font-size: 16px
    }
}