#managementAnnualItems.e-grid {
    border: 0;
}

#managementAnnualItems.e-grid .e-gridcontent tr.e-row .e-rowcell {
    font-size: 14px;
    font-family: 'MyriadPro', sans-serif;
    color: #747876;
    font-weight: 400;
}

#managementAnnualItems .e-gridcontent tr.e-row td.e-rowcell:first-child {
    color: #19836e;
}

#managementAnnualItems.e-grid .e-headercelldiv {
    font-size: 14px;
    line-height: 21px;
    font-family: 'MyriadPro', sans-serif;
    color: #747876;
    font-weight: 400;
    align-items: center;
    display: flex;
}

#managementAnnualItems .active {
    font-size: 13px;
    line-height: 15px;
    font-family: 'MyriadPro', sans-serif;
    font-weight: 400;
    padding: 7px 34px;
    border-radius: 2px;
}

#managementAnnualItems .active.no {
    background-color: #F6D6D2;
    color: #D20340;
}

#managementAnnualItems .active.yes{
    background-color: #E9F2DA;
    color: #69BE28;
}

#managementAnnualItems .e-row {
    cursor: pointer;
}


#managementAnnualItems #managementAnnualItems_toolbarItems {
    background-color: #fff;
    padding: 11px 0;
    border: 1px solid #e0e0e0;
    border-top: 3px solid #F7F9F9;
}

.flex-spacebetween{
    display: flex;
}
#Annual .topContent label {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #007E7A;
    font-family: 'Caecilia';
    font-weight: 600;
    margin: 0 20px;
}

#managementAnnualItems .e-headercontent .e-headercell {
    background-color: #F7F9F9;
}

#managementAnnualItems .e-gridcontent {
    border: 1px solid #e0e0e0;
}

#managementAnnualItems .e-toolbar-item button {
    background-color: #F7F9F9;
}

#managementAnnualItems .editItems {
    display: flex;
    justify-content: space-between;
}

#managementAnnualItems .editItems span {
    cursor: pointer;
}

#modalEditAnnual {
    /* max-height: 100% !important; */
    width: 662px !important;
}

#modalEditAnnual > div:first-child button {
    position: absolute;
    right: -15px;
    left: inherit;
    top: -15px;
}

#modalEditAnnual .e-btn.e-flat.e-primary {
    width: 197px;
    color: #ffffff;
    background-color: #007e7a;
}

#modalEditAnnual .newAnnual div {
    margin-top: 15px;
}

#modalEditAnnual .dropDown {
    display: flex;
    justify-content: space-between;
}

#modalEditAnnual .dropDown div {
    width: 290px;
}

#modalEditAnnual .input label {
    margin: 0px;
}

#modalEditAnnual label:not(.e-error) {
    font-size: 14px;
    line-height: 21px;
    font-family: 'MyriadPro', sans-serif;
    color: #747876;
    font-weight: 600;
}

#modalEditAnnual .e-input-group.error {
    border-color: #D20340;
}

#modalEditAnnual .error {
    border-color: #D20340;
    color: #D20340;
    margin-top: 5px;
}
#modalEditAnnual .e-input-focus.error {
    border: 1px solid #D20340;
}

.btn-modal:disabled{
    background-color: #E3EBEB !important;
}
.e-spinner-pane .e-spinner-inner{
z-index: 999999 !important;
}