.contactslist .e-grid .e-headercell,
.contactslist .e-grid .e-detailheadercell {
    background-color: #f7f9f9;
}

.contactslist .e-grid.e-resize-lines .e-headercell .e-rhandler {
    border: none;
}

.contactslist .e-grid .e-gridcontent tr.e-row td.e-rowcell:first-child span {
    color: #19836e;
    cursor: pointer;
}

.contactslist .e-grid.e-resize-lines .e-headercell .e-rhandler {
    border: none;
}

#formMailingContact .radioList {
    list-style: none;
    margin-top: 10px;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    gap: 30px;
}

.contactItem {
    background-color: #ffffff;
    border: 1px solid #ececec;
    border-radius: 4px;
    margin-bottom: 30px;
}

.contactItem .top {
    padding: 18px;
    border-bottom: 2px solid #f7f9f9;
}

.contactItem .content .line .input.scroll-horizontal {
    overflow: auto;
    max-height: 310px;
}

.contactItem .top .mdl2.mdl2-contacts {
    color: #007e7a;
    font-size: 18px;
}

.contactItem .top h2 {
    color: #007e7a;
    font-family: "Caecilia";
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 15px;
}

.contactItem .content .line {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 18px;
}

.contactItem .content .line .input {
    width: 100%;
}

.contactItem .content .line.x2 .input {
    width: calc(100% / 2 - 22px);
}

.contactItem .content .line.x2.l .input:first-child {
    width: calc(75% - 22px / 2);
}

.contactItem .content .line.x2.l .input:last-child {
    width: calc(25% - 22px / 2);
}

.contactItem .content .line .input .addLineContactMailing {
    display: flex;
    justify-content: space-between;
}

.contactItem .content .line .input .addLineContactMailing .mdl2,
.lineContactMailing .mdl2 {
    background: #f7f9f9;
    line-height: 28px;
    width: 28px;
    border-radius: 4px;
    text-align: center;
    color: #007e7a;
    cursor: pointer;
}

.contactItem .content .line .input .lineContactMailing .numbers {
    position: relative;
    padding: 9px 0 9px 2.3rem;
}

.contactItem .content .line .input .lineContactMailing .dropDownList {
    width: 100%;
    height: auto;
    max-height: 10rem;
    padding: 0 0 .5rem 0;
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 2rem;
    overflow-y: scroll;
    background: #fff;
    border: 1px solid #ececec;
    margin: 0;
    z-index: 1;
}

.contactItem .content .line .input .lineContactMailing .dropDownList .inputFlags {
  width: 90%;
  margin: .5rem 0;
  padding: 9px 0;
  color: #747876;
  font-size: 14px;
  line-height: 17px;
  font-family: "MyriadPro";
  border: 0;
  border-bottom: 1.5px solid #DEDFDE;
  min-height: 30px;
  align-self: center;
  outline: thin dotted;
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: 0px;
}

.contactItem .content .line .input .lineContactMailing .dropDownList .inputFlags:hover {
  border-bottom: 1.5px solid #949494;
}

.contactItem .content .line .input .lineContactMailing .containerCountry {
    width: min-content;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    bottom: 2.2rem;
    z-index: 1;
}

.contactItem .content .line .input .addLineContactMailing .mdl2::before,
.lineContactMailing .mdl2::before {
    font-size: 10px;
}

.contactItem .content .line .input .lineContactMailing .mdl2-minus {
    float: right;
    position: relative;
    bottom: 2.5rem;
}

.contactItem .content .line .input .lineContactMailing .flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    margin: 0;
    border-radius: 0;
    background: #fff;
}

.contactItem .content .line .input .lineContactMailing .flex:hover {
    background: #E2E2E2;
}

.contactItem .content .line .input .lineContactMailing .countryFlag {
    width: 22px;
}

.contactItem .content .line .input .lineContactMailing .countryName {
    margin: 0 0 0 6px;
}

.contactItem .content .line .input .lineContactMailing .containerCountry .countryIcon {
    font-size: 10px;
}

.contactItem .content .line.x4 .input {
    width: calc(100% / 4 - 66px / 3);
}

.contactItem .content .line .input label {
    color: #747876;
    font-family: "MyriadPro";
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
}

.contactItem .content .line .input .mdl2-minus {
    float: right;
    position: relative;
    bottom: 2.5rem;
}

.contactItem .content .line .input .mdl2::before {
    font-size: 10px;
}

.avatar .profileIcon .mdl2.mdl2-user {
    font-size: 14px;
    color: #fff;
}

.avatar .profileIcon {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #007e7a;
    position: absolute;
    right: 8px;
    bottom: 5px;
}

.avatar .briefName {
    font-family: "MyriadPro";
    font-size: 37px;
    color: #007e7a;
}

.groupsBox {
    background-color: #ffffff;
    border: 1px solid #ececec;
    border-radius: 4px;
    margin-bottom: 30px;
}

.groupsBox .top {
    padding: 18px;
    border-bottom: 2px solid #f7f9f9;
}

.groupsBox .top .mdl2 {
    color: #007e7a;
    font-size: 18px;
}

.groupsBox .top h2 {
    color: #007e7a;
    font-family: "Caecilia";
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 15px;
}

.groupsBox .content {
    padding: 20px 18px 13px;
}

.groupsBox .content .e-multi-select-wrapper {
    height: auto;
}

@media screen and (max-width: 700px) {
    .contactItem .content .line.x4 .input {
        width: calc(100% / 2 - 22px);
    }
}

@media screen and (max-width: 500px) {
    .contactItem .content .line.x2.l .input:first-child,
    .contactItem .content .line.x2.l .input:last-child,
    .contactItem .content .line.x2 .input,
    .contactItem .content .line.x4 .input {
        width: 100%;
    }

    .contactItem .content .line .input .lineContactMailing .mdl2-minus {
        bottom: 2.9rem;
    }
}
