#managementItems.e-grid {
    border: 0;
}

#managementItems.e-grid .e-gridcontent tr.e-row .e-rowcell {
    font-size: 14px;
    font-family: 'MyriadPro', sans-serif;
    color: #747876;
    font-weight: 400;
}

#managementItems .e-gridcontent tr.e-row td.e-rowcell:first-child {
    color: #19836e;
}

#managementItems.e-grid .e-headercelldiv {
    font-size: 14px;
    line-height: 21px;
    font-family: 'MyriadPro', sans-serif;
    color: #747876;
    font-weight: 400;
    display: flex;
    align-items: center;
}

#managementItems .e-toolbar .e-toolbar-items div {
    margin-left: 20px;
    background-color: #F7F9F9;
}

#managementItems .e-toolbar .e-toolbar-items {
    background: #fff;
}

#managementItems #managementItems_toolbarItems {
    background-color: #fff;
    padding: 11px 0;
    border: 1px solid #e0e0e0;
}

#managementItems .active {
    font-size: 13px;
    line-height: 15px;
    font-family: 'MyriadPro', sans-serif;
    font-weight: 400;
    padding: 7px 34px;
    border-radius: 2px;
}

#managementItems .active.no {
    background-color: #F6D6D2;
    color: #D20340;
}

#managementItems .active.yes{
    background-color: #E9F2DA;
    color: #69BE28;
}

#managementItems .e-row {
    cursor: pointer;
}

.topContent span.mdl2 {
    font-size: 18px;
    color: #007E7A;
}

.topContent {
    display: flex;
    align-items: center;
    padding: 20px 23px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
}


.topContent label {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #007E7A;
    font-family: 'Caecilia';
    font-weight: 600;
    margin: 0 20px;
}

.topContent.caixa {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.topContent h2 {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #007e7a;
    font-family: "Caecilia";
    font-weight: 600;
    margin: 0 20px;
}

#modalAddOrEdit .e-input-group.error {
    border-color: #D20340;
}

#modalAddOrEdit .error {
    border-color: #D20340;
    color: #D20340;
    margin-top: 5px;
}
#modalAddOrEdit .e-input-focus.error {
    border: 1px solid #D20340;
}


#addEditDialog label{
    font-size: 13px;
    font-family: 'MyriadPro', sans-serif;
    color: #747876;
}
#addEditDialog .e-float-input{
    margin-top: 0px;
}

.btn-modal {
    width: 198px;
    margin-top: 40px;
    background-color: #007e7a !important;
    border-color: transparent;
    color: #fff !important;
    font-size: 13px;
    line-height: 15px;
    font-family: 'MyriadPro', sans-serif;
}
.btn-modal:hover{
    background-color: #1f8d8a;
    border-color: transparent;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    color: #fff;
}
.btn-wrapper{
    display: flex;
    justify-content: flex-end;

}

#managementItems .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon,
#managementItems .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
    color: #007E7A;
}

#managementItems .e-headercontent .e-headercell {
    background-color: #F7F9F9;
}

#managementItems .e-gridcontent {
    border: 1px solid #e0e0e0;
}

#managementItems .editItems {
    display: flex;
    justify-content: center;
}

#managementItems .editItems span {
    cursor: pointer;
}

#modalEdit > div:first-child button {
    position: absolute;
    right: -15px;
    left: inherit;
    top: -15px;
}

#modalEdit.e-dialog {
    min-height: 55%;
}

#modalEditInternal {
    max-height: calc(100% - 200px) !important;
    width: 662px !important;
}

#modalEditInternal .e-btn.e-flat.e-primary {
    width: 197px;
    color: #ffffff;
    background-color: #007e7a;
}

#modalEditInternal .bannerDelete {
    background-color: #D20340 !important;
    float: left;
}

#modalEditInternal {
    width: 521px;
    min-height: 180px;
    max-height: 506px!important;
}

#modalEditInternal .newInternal div:last-child {
    margin-top: 25px;
    width: 50%;
}

#modalEditInternal .newInternal .input #area {
    margin-top: 10px;
}

#modalEditInternal > div:first-child button {
    position: absolute;
    right: -15px;
    left: inherit;
    top: -15px;
}