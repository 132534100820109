/* screen signature */

#loadSignature {
    margin-top: 120px;
}

.row .col-12 .e-gridcontent .e-content .e-row .e-rowcell .title {
    color: #19836e;
    cursor: pointer;
    font-size: 14px;
    font-family: "MyriadPro", sans-serif;
    font-weight: 400;
}

/* screen signatureItem */

.line {
    display: flex;
    justify-content: space-between;
}

.orderButtons{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    margin-right: .5rem;
}
.orderButtons span {
    background-color: #FFFFFF;
    border-radius: 5px;
    width: 100%;
    padding: .1rem .5rem;
    cursor: pointer;
    text-align: center; 
    color: #007e7a;
    opacity: 0.6;
}
.orderButtons span:hover {
    opacity: 1;
}
.orderButtons span.upButton {
    border-bottom: #DFE0DF solid 1px;
}
.orderButtons span.downButton {
    transform: rotate(180deg);
    border-top: #DFE0DF solid 1px;
}

.alignCenter {
    align-items: center;
}

.icon {
    height: 24px;
}

.buttonsStyle {
    display: flex;
    align-items: center;
    width: 50%;
    margin: auto;
    justify-content: center;
    gap: 20px;
}

.buttonsStyle button:last-of-type {
    margin: 0;
}

.line.x2 .input {
    width: calc(50% - 22px);
}

.clear-float {
    clear: both;
}

.textBox .line.x2 .input input#title {
    font-size: 14px;
    line-height: 22px;
}

.addLine-minus .mdl2-minus,
.addLine-plus .mdl2-plus {
    position: relative;
    float: right;
    bottom: 0;
    background: #ffffff;
    font-size: 10px;
    line-height: 28px;
    min-width: 28px;
    border-radius: 4px;
    text-align: center;
    color: #007e7a;
    cursor: pointer;
    border-bottom: 1px solid rgba(116, 120, 118, 0.24);
}

.addLine-minus:last-child {
    margin-bottom: 45px;
}

#formSignatureItem .boxRichText {
    padding: 8px 18px;
}

@media (max-width: 648px) {
    .line {
        flex-direction: column;
    }

    .line.x2 .input {
        width: 100%;
    }
}
