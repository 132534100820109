/*--------Envio de Conteúdo CSS -------*/

#sendContent {
    background-color: #fff;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    padding: 26px 18px 41px;
    margin: 0 0 30px;
}

#sendContent label {
    font-family: 'MyriadPro', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #747876;
}

#sendContent #formSendItem .textBoxDiv {
    display: flex;
    padding: 30px 15px 33px;
    background-color: #F7F9F9;
    border: 1px solid #ECECEC;
    border-radius: 4px;
}

#sendContent #formSendItem .textBoxStyle {
    width: 100%;
    padding: 0 10px;
}

#sendContent #formSendItem .textBoxStyle .e-input-group {
    background-color: #fff;
    padding: 3px 10px;
}

#sendContent .e-checkbox-wrapper {
    padding: 23px 0;
}

#sendContent .e-icons {
    border: 1px solid #707070;
}

#sendContent .e-label {
    font-family: 'MyriadPro', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #747876;
}

#sendContent #formSendItem .sendGroup {
    padding: 20px 15px 33px;
    background-color: #fff;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    margin-top: 16px;
    padding: 0;
}

#sendContent #formSendItem .sendTop {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 8px;
    align-items: center;
}

#sendContent #formSendItem .sendTop > div {
    display: flex;
    align-items: center;
}

#sendContent #formSendItem .sendTop h1 {
    color: #007E7A;
    font-weight: 600;
    font-family: 'Caecilia';
    font-size: 16px;
    margin: 0;
    padding-left: 12px;
}

#sendContent #formSendItem .sendTop button {
    width: 196px;
    height: 28px;
    background-color: #F7F9F9;
    color: #007E7A;
    font-family: 'MyriadPro', sans-serif;
    font-size: 13px;
    border: none;
    padding: 7px 52px;
}

#sendContent #formSendItem .sendTop button span {
    white-space: nowrap;
}

#sendContent #formSendItem .mdl2 {
    color: #007E7A;
    font-size: 26px;
}

#sendContent hr {
    color: #ECECEC;
    margin: 0;
}

#sendContent #formSendItem .sendGroup .groupsDiv {
    padding: 16px;
}

#sendContent #formSendItem .sendGroup .groupsDiv #list {
    background-color: #F7F9F9;
    padding-left: 10px;
}

/*----------Actions CSS------------*/

.actions {
    background-color: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    margin-bottom: 30px;
}

.actions.profile {
    margin-top: -16px;
}

.actions .top {
    padding: 18px;
    border-bottom: 2px solid #F7F9F9;
}

.actions .top .mdl2 {
    color: #007E7A;
    font-size: 18px;
}

.actions .top h2 {
    color: #007E7A;
    font-family: 'Caecilia';
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 15px;
}

.actions .top button {
    margin: 0;
    font-size: 14px;
    line-height: 15px;
    font-family: 'MyriadPro' !important;
    text-transform: none;
    font-weight: 400;
}

.actions .highlight {
    display: flex;
    padding: 8.5px 18px;
    flex-direction: column;
    justify-content: center;
    background-color: #F7F9F9;
    border-bottom: 1px solid #ECECEC;
}

.actions .highlight button {
    margin: 0;
    font-size: 14px;
    line-height: 15px;
    font-family: 'MyriadPro' !important;
    font-weight: 400;
    background-color: #fff !important;
}

.actions .content {
    padding: 20px 18px 13px
}

.actions .content button {
    margin: 0;
    font-size: 13px;
    line-height: 15px;
    font-family: 'MyriadPro' !important;
    font-weight: 400;
    margin-bottom: 4%;
}

/*--------Mailing Contato CSS -------*/

#sidebar .sidebar-content{
    padding: 0 10px;
}

#sidebar .upload {
    border: 1px solid #F1F1F1;
    border-radius: 4px;
    padding: 9px 8px;
}

#sidebar .upload > div {
    border: 1px dotted #D8CDCD;
    border-radius: 4px;
}

#sidebar .upload > div.new {
    background-color: #F7F9F9;
    text-align: center;
    height: 190px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#sidebar .upload > div p {
    color: #747876;
    font-size: 16px;
    line-height: 19px;
    font-family: 'MyriadPro';
    font-weight: 400;
    margin: 35px 0 0;
}

#sidebar .upload > div p span {
    cursor: pointer;
    color: #007E7A;
}

#sidebar .e-upload {
    border: none;
}

#sidebar .e-upload .e-file-select-wrap {
    display: none;
}

#sidebar .e-upload .e-upload-files {
    background-color: #F7F9F9;
    border: 1px solid #F1F1F1;
}

#sidebar .e-btn.e-flat.e-primary {
    width: 197px;
    color: #ffffff;
    background-color: #007e7a;
    margin: 25px 5px 20px 20px;
}
#sidebar .btnDownload, #sidebar .btnImport{
    display: inline-block;
    margin-top: 5px;
}
#sidebar .btnImport {
    margin-left: 17px;
}

#sidebar #gridFeedback{
    margin-top: 15px;
}

#sidebar #gridFeedback .e-table{
    width: auto;
}

#sidebar #loading{
    display: inline-block;
    width: 75px;
}

#sidebar #loading .e-spinner-pane{
    position: relative;
}

#sidebar #percentage {
    font-size: 14px;
}

@media screen and (max-width: 500px) {
    #sendContent #formSendItem .sendTop {
        flex-direction: column;
    }

    #sendContent #formSendItem .sendTop button {
        width: 100%;
        margin-top: 10px;
    }

    #sendContent #formSendItem .textBoxDiv {
        flex-direction: column;
    }
    
    #sendContent #formSendItem .textBoxStyle {
        margin-top: 10px;
    }
}