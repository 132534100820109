#managementAgreement {
    border: none;
    margin-bottom: 20px;
}

#Agreements {
    margin-top: 60px;
}

#managementAgreement .e-columnheader th {
    background-color: #F7F9F9;
    color: #747876;
    font-size: 14px;
}

#managementAgreement table tr td {
    color: #747876;
    padding: 15px 18px;
    font-size: 14px;
    opacity: 1;
}

#managementAgreement .contrato {
    color: #007E7A;
}

#managementAgreement .editItems {
    display: flex;
    justify-content: space-between;
}

#managementAgreement .editItems span{
    cursor: pointer;
}

.historicDiv {
    display: flex;
    align-items: center;
    padding: 19px 16px;
    margin-top: 12px;
    background-color: #fff;
    border: solid 1px #e0e0e0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.historicDiv h2 {
    font-size: 16px;
    color: #007E7A;
    margin: 0 20px 0;
    font-family: 'Caecilia';
    font-weight: 600;
}

.historicDiv span {
    font-size: 18px;
    color: #007E7A;
}

#modalEdit.e-dialog {
    min-height: 55%;
}

#modalEdit > div:first-child button {
    position: absolute;
    right: -15px;
    left: inherit;
    top: -15px;
}

#modalEdit .upload {
    border: 1px solid #F1F1F1;
    border-radius: 4px;
    padding: 9px 8px;
}

#modalEdit .upload > div {
    border: 1px dotted #D8CDCD;
    border-radius: 4px;
}

#modalEdit .upload > div.new {
    background-color: #F7F9F9;
    text-align: center;
    height: 245px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#modalEdit .upload > div img {
    width: 100%;
}

#modalEdit .upload > div p {
    color: #747876;
    font-size: 16px;
    line-height: 19px;
    font-family: 'MyriadPro';
    font-weight: 400;
    margin: 35px 0 0;
}

#modalEdit .upload > div p span {
    cursor: pointer;
    color: #007E7A;
}

#modalEdit .upload > div span#change {
    cursor: pointer;
    display: block;
}

#modalEdit .half {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#modalEdit .half > div {
    width: 48%;
}

#modalEdit .input {
    margin-top: 21px;
    width: 100%;
}

#modalEdit label:not(.e-error) {
    font-size: 14px;
    line-height: 21px;
    font-family: 'MyriadPro', sans-serif;
    color: #747876;
    font-weight: 600;
}

#modalEdit .e-upload {
    border: none;
}

#modalEdit .e-upload .e-file-select-wrap {
    display: none;
}

#modalEdit .e-upload .e-upload-files {
    background-color: #F7F9F9;
    border: 1px solid #F1F1F1;
}

#modalEdit .e-btn.e-flat.e-primary {
    width: 197px;
    color: #ffffff;
    background-color: #007e7a;
}

#modalEdit .bannerDelete {
    background-color: #D20340 !important;
    float: left;
}

#modalEdit #validateImage {
    color: #ff0000;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
    font-size: 12px;
    font-weight: normal;
    padding-top: 4px;
}

#modalEdit .input label {
    margin: 0px;
}

#modalEdit {
    max-height: calc(100% - 50px) !important;
}

#modalEdit .wrapFlexDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

#modalEdit .wrapFlexDiv .input {
    width: 290px;
}

#modalEdit .e-dialog .e-footer-content {
    padding: 8px 8px 43px;
}

#modalEdit .e-input-group.e-control-wrapper.e-valid-input {
    color: #747876;
    font-size: 14px;
    line-height: 17px;
    font-family: "MyriadPro" !important;
    opacity: 0.8;
}

#modalEdit .wrapFlexDiv .input span {
    background-color: #F7F9F9;
    padding: 3px 7px;
}

#managementAgreement .e-gridpager.e-control.e-pager.e-lib {
    padding: 10px 0;
}

#managementAgreement .e-gridcontent {
    border: 1px solid #e0e0e0;
}

.e-input-group.error {
    border-color: #D20340;
}

.error {
    border-color: #D20340;
    color: #D20340;
    margin-top: 5px;
}

.labelModal.delete {
    color: #BB133E;
}

#managementContract .editItems {
    display: flex;
    justify-content: space-between;
}

#managementContract .editItems span {
    cursor: pointer;
}

#managementContract.e-grid .e-table, 
#managementContract.e-grid .e-columnheader, 
#managementContract.e-grid .e-columnheader th {
    background-color: transparent;
}

#managementContract .contrato {
    color: #007E7A;
}

@media screen and (max-width: 670px) {
    #modalEdit .wrapFlexDiv .input {
        width: 100%;
    }
}