/* Fonte */
@font-face {
    font-family: 'Caecilia';
    src: url('fonts/CaeciliaLTStd-Light.eot') format('embedded-opentype'), url('fonts/CaeciliaLTStd-Light.otf') format('opentype'), url('fonts/CaeciliaLTStd-Light.svg') format('svg'), url('fonts/CaeciliaLTStd-Light.ttf') format('truetype'), url('fonts/CaeciliaLTStd-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: 'Caecilia';
    src: url('fonts/CaeciliaLTStd-Roman.eot') format('embedded-opentype'), url('fonts/CaeciliaLTStd-Roman.otf') format('opentype'), url('fonts/CaeciliaLTStd-Roman.svg') format('svg'), url('fonts/CaeciliaLTStd-Roman.ttf') format('truetype'), url('fonts/CaeciliaLTStd-Roman.woff') format('woff');
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: 'Caecilia';
    src: url('fonts/CaeciliaLTStd-Bold.eot') format('embedded-opentype'), url('fonts/CaeciliaLTStd-Bold.otf') format('opentype'), url('fonts/CaeciliaLTStd-Bold.svg') format('svg'), url('fonts/CaeciliaLTStd-Bold.ttf') format('truetype'), url('fonts/CaeciliaLTStd-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: 'Caecilia';
    src: url('fonts/CaeciliaLTStd-Heavy.eot') format('embedded-opentype'), url('fonts/CaeciliaLTStd-Heavy.otf') format('opentype'), url('fonts/CaeciliaLTStd-Heavy.svg') format('svg'), url('fonts/CaeciliaLTStd-Heavy.ttf') format('truetype'), url('fonts/CaeciliaLTStd-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal
}

@font-face {
    font-family: 'MyriadPro';
    src: url('fonts/MyriadPro-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'MyriadPro';
    src: url('fonts/MyriadPro-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}


@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";

@import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-icons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";

/*  */

body {
    background-color: #F7F9F9;
    font-family: 'MyriadPro';
    font-weight: 400;
}

a {
    color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.e-control {
    font-family: "MyriadPro" !important;
    font-weight: 400;
}

.e-filled, .e-filled:hover {
    border-radius: 0 !important;
    background-color: #F7F9F9 !important;
    padding-bottom: 1px !important;
}

.e-filled.e-input-focus {
    background-color: #F7F9F9 !important;
}

.e-input::selection {
    background: #E6E6E6 !important;
}


#customTbarDlg .e-btn.e-flat.e-primary:disabled {
    color: #FFFFFF;
}

/*  */

.DefaultTop {
    width: 100%;
    height: 75px;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
}

.containerTop {
    margin-top: -80px;
    margin-bottom: 15px;
}

.containerTop.semCaixa {
    margin-top: -70px;
}

.withoutTopContent.e-grid {
    border: 0;
    margin-top: 30px;
}

.avatar {
    width: 94px;
    height: 94px;
    background-color: #ffffff;
    border-radius: 50%;
    margin: auto;
    padding: 9px;
    position: relative;
    cursor: pointer;
    border: 1px solid #ECECEC;
    margin-bottom: 25px;
}

.avatar .img {
    width: 76px;
    height: 76px;
    background-color: #ffffff;
    border: 1px solid;
    border-color: #ECECEC;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar .mdl2 {
    font-size: 37px;
    color: #007e7a;
}

.e-icons#close:before {
    content: "\e945";
}

/* */

.topTitleButtons {
    width: 100%;
}

.topTitleButtons .title,
.topTitleButtons .buttons {
    margin-bottom: 10px;
}

.topTitleButtons .buttons {
    text-align: right;
}

.topTitleButtons .title h1 {
    color: #007E7A;
    font-size: 28px;
    line-height: 34px;
    font-weight: 400;
    font-family: 'Caecilia';
}

.searchUsers {
    width: calc(100% - 250px);
    margin-left: 15px;
}

.SearchTitle {
    border-color: #e0e0e0;
    color: #007e7a;
    font-size: 15px;
    position: relative;
    display: flex;
    align-items: center;
}

.SearchTitle p {
    font-size: 18px;
    margin: 0;
    font-family: 'Caecilia';
    font-weight: 700;
}

.SearchTitle:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 1px;
    height: 18px;
    background-color: #f7f9f9;
}

.btnBusca > div {
    margin-left: 15px;
}

.filtroUsuarios {
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    min-height: 65px;
}

.filtrosOcultos {
    background-color: #f7f9f9;
    border: 1px solid #e0e0e0;
    padding: 15px 20px;
    flex-wrap: wrap;
    left: -1px;
    z-index: 1;
    width: calc(100% + 2px);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.filtrosOcultos > span {
    margin: 5px;
}

.filtrosOcultos.dois > span {
    width: calc(100% / 2 - 10px) !important;
}

.filtrosOcultos.quatro > span {
    width: calc(100% / 4 - 10px) !important;
}

.filtrosOcultos.seis > span {
    width: calc(100% / 6 - 10px) !important;
}

.filtrosOcultos .e-filled.e-input-group, 
.filtrosOcultos .e-filled.e-input-group.e-control-wrapper {
    background: #fff !important;
}

.busca {
    border: 1px solid;
    border-color: #e0e0e0;
    background-color: #fff;
    border-radius: 4px;
    position: relative;
}

.busca.aberto {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/*  */

.e-grid.e-grid .e-table,
.e-grid.e-grid .e-headercell {
    background-color: transparent;
}

.e-gridcontent {
    border: 1px solid #e0e0e0;
    border-bottom: none;
}

.e-gridcontent.e-gridcontent table tr td {
    color: #747876;
    padding: 15px 18px;
    font-size: 14px;
}

.e-columnheader.e-columnheader th div span {
    color: #747876;
    font-size: 14px;
    font-weight: 600;
}

#gridcomp .emailTemplate {
    color: #007E7A;
    cursor: pointer;
}

.e-gridpager.e-control.e-pager.e-lib {
    border-width: 1px;
}

.e-columnheader th {
    background-color: #F7F9F9;
}

.e-columnheader .e-headercelldiv {
    padding-left: 3px;
}


.e-grid.e-grid .e-emptyrow td,
.e-grid.e-grid .e-groupdroparea {
    color: #747876;
    padding: 15px 18px;
    font-size: 14px;
}

.e-grid.e-grid .e-headercontent {
    border: none;
}

.e-grid.e-grid .e-gridcontent tr.e-row .e-rowcell {
    font-size: 14px;
    font-family: 'MyriadPro', sans-serif;
    color: #747876;
    font-weight: 400;
}

.e-gridheader.e-gridheader.e-lib.e-droppable {
    border: 0;
    padding: 0 !important;
    background: transparent;
}

.caixaArrastar {
    background-color: #FFFFFF;
}

.e-grid.e-grid .e-groupdroparea {
	background-color: #F7F9F9;
    border: 1px solid #e0e0e0;
    position: relative;
    padding: 20px;
    display: flex;
    align-items: center;
	border-radius: 2px;
}

.e-groupdroparea.e-groupdroparea.e-lib.e-droppable.e-draggable::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border: 11px solid #fff;
	pointer-events: none;
	border-radius: 2px;
}

.e-groupdroparea.e-groupdroparea.e-lib.e-droppable.e-draggable::after {
	content: "";
    position: absolute;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
    border: 1px solid #e0e0e0;
    pointer-events: none;
    border-radius: 2px;
}

.e-grid.e-grid .e-groupdroparea.e-grouped {
	background-color: #F7F9F9;
	border: 1px solid #e0e0e0;
	padding: 15px;
}

.e-grid.e-grid .e-groupheadercell,
.e-grid.e-grid .e-groupheadercell:hover {
    margin-left: 5px;
	background-color: #fff;
    border-radius: 2px;
}

.e-grid.e-grid .e-groupdroparea .e-icons {
    color: #BEBEBE;
}

/*  */

label label.e-error {
    margin: 0;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 0;
    box-shadow: none;
}

::-webkit-scrollbar-thumb {
    background: #757575;
    border-radius: 0;
    box-shadow: none;
}

/*  */
.e-ddt .e-overflow.e-wrap-count,
.e-ddt .e-overflow.e-show-text {
    padding-top: 12px;
}

.e-ddt.e-input-group.e-control-wrapper.e-show-chip .e-clear-icon {
    bottom: 10px;
    background: transparent;
}

.e-ddt.e-input-group.e-control-wrapper.e-show-text.e-show-dd-icon .e-clear-icon {
    bottom: 10px;
    right: 27px;
}

.e-ddt.e-input-group.e-control-wrapper.e-show-text .e-ddt-icon {
    bottom: 5px;
}

.e-file-status{
    display: none !important;
}

#azure-file .e-file-status{
    display: block !important;
}

.e-input-group .e-input[disabled], 
.e-input-group.e-control-wrapper .e-input[disabled], 
.e-input-group.e-disabled input.e-input, 
.e-input-group.e-control-wrapper.e-disabled input.e-input {
    opacity: 0.6;
}

.input label:not(.e-error) {
    color: #747876;
    font-family: "MyriadPro";
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
}

.clipboard-div {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.clipboard-div span {
    margin-bottom: 10px;
}

#modalCopyPaste {
    width: fit-content !important;
}

.input-container {
    display: flex;
    flex-direction: row;
}

.input-container .urlText {
    border: 1px solid rgba(0,0,0,0.2);
    width: 550px;
    word-break: break-word;
    padding: 10px;
}

.btn-container {
    width: 40px;
    margin-left: 5px;
    border: 1px solid rgba(0,0,0,0.2);
    padding: 5px;
}

.btn-container .clipboard-icon {
    background-image: url('../css/imgs/clipboard.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-color: white;
    background-position: 50%;
    width: 100%;
    height: 100%;
    outline: none;
    border: 0;
    transition: all 0.25s ease-in-out;
}

.btn-container:active {
    outline: 5px auto rgba(0,0,0,0.2);
}

.e-input-group.e-input-group input.e-input, 
.e-input-group.e-input-group.e-control-wrapper input.e-input, 
.e-input-group.e-input-group textarea.e-input, 
.e-input-group.e-input-group.e-control-wrapper textarea.e-input {
    color: #747876;
    font-size: 14px;
    line-height: 17px;
    font-family: "MyriadPro" !important;
    padding: 9px 0;
}

.e-input-group.e-input-group input.e-input:focus, 
.e-input-group.e-input-group.e-control-wrapper input.e-input:focus, 
.e-input-group.e-input-group textarea.e-input:focus, 
.e-input-group.e-input-group.e-control-wrapper textarea.e-input:focus, 
.e-input-group.e-input-group.e-input-focus input.e-input, 
.e-input-group.e-input-group.e-control-wrapper.e-input-focus input.e-input {
    padding: 9px 0;
}

.e-pagerdropdown.e-pagerdropdown .e-input-group.e-control-wrapper input.e-input {
    padding: 4px 0;
}

input.e-input.e-css, 
.e-input-group input, 
.e-input-group.e-control-wrapper input, 
.e-input-group input.e-input, 
.e-input-group.e-control-wrapper input.e-input {
    font-size: 14px;
    line-height: 17px;
    font-family: "MyriadPro" !important;
}

/*  */
#BoxInputCount {
    position: relative;
    margin-bottom: .9375rem;
    height: 4.5rem;
}
  
#InputCount {
    position: absolute;
    right: .25rem;
    bottom: 1rem;
    font-size: .7rem;
    color: #747876;
} 

#deleteModal, #fwdConfirmationModal {
    width: 446px;  
}

#deleteModal_dialog-content {
    padding: 0;
    min-height: 155px;
}

#deleteModal .e-footer-content {
    display: flex;
    justify-content: space-between;
    padding-bottom: 18px;
}

#deleteModal .e-footer-content button:last-child {
    width: 197px;
    color: #ffffff;
    background-color: #007e7a;
}

#deleteModal .e-footer-content button:first-child {
    width: 197px;
    color: #747876;
    background-color: #F7F9F9;
}

#deleteModal .divTrash {
    background-color: #F6D6D2;
    display: flex;
    border-radius: 50%;
    width: 55px;
    text-align: center;
    height: 55px;
    margin: 18px 18px 0;
}

#deleteModal .divTrash .mdl2.mdl2-trash {
    color: #BB133E;
    font-size: 38px;
    padding: 8px 0 0 10px;
}

#deleteModal .deleteComplete .confirmTrash .success.mdl2.mdl2-trash {
    color: #BB133E;
    font-size: 64px;
    padding: 12px 0 0 5px;
    margin: 0 auto;
}

#deleteModal .deleteComplete {
    display: flex;
    flex-direction: column;
}

#deleteModal .deleteComplete label {
    margin: 18px auto;
    color: #BB133E;
    font-family: 'Caecilia';
    font-weight: 700;
    font-size: 18px;
}

#deleteModal .deleteComplete button {
    width: 412px;
    height: 28px;
    border: none;
    color: #747876;
    background-color: #f7f9f9;
    margin: 5px auto 25px;
}

#deleteModal .confirmTrash {
    background-color: #F6D6D2;
    display: flex;
    border-radius: 50%;
    width: 107px;
    text-align: center;
    height: 107px;
    border: solid #f7f9f9;
    border-width: 8px;
    margin: 17px auto 0;
}

#deleteModal .confirmTrash button{
    background-color: #F6D6D2;
    display: flex;
    border-radius: 50%;
    width: 107px;
    text-align: center;
    height: 107px;
    border: solid #f7f9f9;
    border-width: 8px;
    margin: 0 auto;
}

#deleteModal .phraseDelete {
    margin-left: 30px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    min-width: 322px;
    text-align: initial;
}
#fwdConfirmationModal .phraseConfirm {
    margin-left: 30px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    min-width: 322px;
    text-align: center;
}

#fwdConfirmationModal #confirm {
    color: #007E7A;
    font-weight: 700;
    font-family: 'Caecilia';
    font-size: 18px;
}

#deleteModal #delete {
    color: #BB133E;
    font-weight: 700;
    font-family: 'Caecilia';
    font-size: 18px;
}

#deleteModal #labelDelete, #fwdConfirmationModal #labelConfirm {
    font-family: 'Caecilia';
    font-size: 14px;
    color: #747876;
    margin-top: 1rem;
}
#fwdConfirmationModal #labelConfirm span {
    color: #007E7A;
    cursor: pointer;
}
#fwdConfirmationModal #labelConfirm span:hover {
    color: #02948f;
    text-decoration: underline;
}

#deleteModal hr, #fwdConfirmationModal hr {
    margin: 15px 0 0;
}

#deleteModal .footerButtons {
    display: flex;
    justify-content: space-around;
    padding: 19px 0;
}
#fwdConfirmationModal .footerButtons {
    display: flex;
    justify-content: flex-end;
    padding: 19px 0;
}
#fwdConfirmationModal .footerButtons button {
    margin: 0 !important;
}

#deleteModal .footerButtons button, #fwdConfirmationModal .footerButtons button {
    width: 198px;
    height: 28px;
    border: none;
}

#deleteModal .footerButtons button:first-child {
    background-color: #F7F9F9;
    color: #747876; 
}

#deleteModal .footerButtons button:last-child, #fwdConfirmationModal .footerButtons button {
    background-color: #007E7A;
    color: #fff;
}

/*  */


.e-dialog.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
    position: absolute;
    right: -15px;
    left: inherit;
    top: -15px;
}

.e-dialog.e-dialog.e-popup {
    max-height: 94% !important;
}

.e-popup.e-popup.e-popup-open.e-dialog {
    margin: 20px;
}


#menuMobile.e-listview .e-content {
    height: calc(100vh - 48px);
    overflow-y: auto;
}

.e-checkboxfilter .e-chk-hidden {
    display: none;
}

.e-hide {
    display: none;
}

.toast-icons.e-success::before {
    content: "\e701";
}

.toast-icons.e-error::before {
content: "\e700";
}

.toast-icons.e-info::before {
content: "\e704";
}

.toast-icons.e-warning::before {
content: "\e703";
}

/*  */

@media screen and (min-width: 768px) {
    .filtrosOcultos.seis.plus > span:nth-child(5n+1) {
        width: calc(100% / 3 - 10px) !important;
    }    
}

@media screen and (max-width: 768px) {
    .filtrosOcultos.seis > span {
        width: calc(100% / 3 - 10px) !important;
    }
    .filtrosOcultos.quatro > span {
        width: calc(100% / 2 - 10px) !important;
    }

    .DefaultTop {
        height: 105px;
    }
    .containerTop {
        margin-top: -100px;
    }
}

@media screen and (max-width: 500px) {
    .filtrosOcultos.dois > span,
    .filtrosOcultos.quatro > span,
    .filtrosOcultos.seis > span {
        width: calc(100% - 10px) !important;
    }
    .filtroUsuarios {
        flex-wrap: wrap;
    }
    .SearchTitle p {
        display: none;
    }
    .searchUsers {
        width: calc(100% - 40px);
    }
    .btnBusca {
        width: 100%;
        margin-left: auto;
        justify-content: flex-end;
        margin-top: 10px;
    }
    .input {
        width: 100%;
    }
    #deleteModal {
        width: calc(100% - 30px);
        margin: 0 15px;  
    }
    #deleteModal .footerButtons button {
        width: calc(50% - 10px);
        margin: 0 5px;
    }
    #deleteModal .phraseDelete {
        margin-left: 15px;
    }
}
