.container, 
.container-sm, 
.container-md, 
.container-lg, 
.container-xl {
    max-width: 1305px;
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 767px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

header.geral nav.first {
    padding: 13px 13px 15px;
}

header.geral nav.first svg {
    margin-right: 10px;
}

header.geral nav.first .navbar-brand {
    font-family: 'Caecilia';
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #747876;
    padding: 0;
    margin: 10px 0 0;
    padding: 0 0 0 10px;
    position: relative;
}

header.geral nav.first .navbar-brand:before {
    content: "";
    position: absolute;
    border-left: 2px dotted #747876;
    height: 10px;
    bottom: 5px;
    left: 0;
}

header.geral nav.first .navbar-brand#initials {
    display: none;
}

header.geral nav.second {
    background-color: #F7F9F9;
}

header.geral nav.second .nav-item {
    font-family: 'MyriadPro';
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #747876;
}

header.geral nav.second .backMenu {
    margin: 12px 10px;
}

/*MENU ADMIN*/

.e-dropdown-btn.adminMenu:hover,
.e-dropdown-btn.e-btn.adminMenu {
    background-color: transparent;
    box-shadow: none !important;
}

.e-dropdown-btn.adminMenu {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0 5px;
}


.adminMenu .user {
    background-color: #f7f9f9;
    padding: 6px 12px 4px;
    border-radius: 4px;
}

.adminMenu .user span {
    font-family: 'MyriadPro';
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #727272;
}

.adminMenu .user .img {
    margin-left: 15px;
    margin-top: -32px;
    margin-bottom: -25px;
    border: 1px solid #E1E1E1;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
}

/*DROPDOWN MENU ADMIN*/

ul#menuAd {
    min-width: 160px;
    margin-top: 10px;
    position: relative;
    overflow: inherit;
    padding: 0;
}

ul#menuAd:before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #ffffff;
    position: absolute;
    top: -5px;
    transform: rotate(45deg);
    right: 50%;
    right: 15%;
}

ul#menuAd > .nav-item:not(.logout) {
    border-bottom: 1px solid #ECECEC;
}

ul#menuAd .nav-item .nav-link {
    color: #747876;
    font-family: 'MyriadPro';
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    padding: 10px 16px;
}

ul#menuAd .nav-item.logout .nav-link {
    color: #BB133E;
}

ul#menuAd ul.admin {
    padding: 0;
    box-shadow: none;
    background-color: #F7F9F9;
}

/*ICON MENU ADMIN*/

.menuItemBox {
    margin: 7px 0 0 10px;
    width: 16px;
    height: 16px;
}

.menuIcon {
    cursor: pointer;
    color: #007E7A;
    position: absolute;
    height: 0;
    border-bottom: 3px dotted currentColor;
    padding: 7px 8px 7px 9px;
    width: 4px;
}

.menuIcon:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 17px;
    height: 0;
    border-bottom: 3px dotted currentColor;
}

.menuIcon:after {
    content: '';
    position: absolute;
    top: 7px;
    left: 0;
    width: 17px;
    height: 0;
    border-bottom: 3px dotted currentColor;
}

.e-dropdown-btn.adminMenu:hover .menuIcon {
    padding: 6px 7px 6px 8px;
}

.e-dropdown-btn.adminMenu:hover .menuIcon:before {
    width: 15px;
    top: 2px;
}

.e-dropdown-btn.adminMenu:hover .menuIcon:after {
    width: 15px;
}

.e-folder::before {
    content: "\e82f";
}

/*MAIN MENU*/


.geral .e-menu-wrapper {
    width: 100%;
}

.geral .e-menu-wrapper ul#mainMenu {
    width: 100%;
}

.geral .e-menu-wrapper ul#mainMenu .e-menu-item .e-caret,
.e-menu-wrapper .e-ul.e-menu-parent .e-menu-item .e-caret {
    color: #EDB111;
}

.geral .e-menu-wrapper ul.e-menu#mainMenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.geral .e-menu-wrapper ul#mainMenu .e-menu-item,
.e-menu-wrapper .e-ul.e-menu-parent .e-menu-item,
.e-menu-wrapper .e-ul.e-menu-parent .e-menu-item .e-menu-url {
    color: #747876;
    font-size: 13px;
    font-family: 'MyriadPro';
    font-weight: 400;
    align-content: center;
}

.geral .e-menu-wrapper ul#mainMenu .e-menu-item.e-focused,
.geral .e-menu-wrapper ul#mainMenu .e-menu-item.e-selected,
.geral .e-menu-wrapper ul#mainMenu .e-menu-item.e-focused .e-menu-icon,
.geral .e-menu-wrapper ul#mainMenu .e-menu-item.e-selected .e-menu-icon,
.e-menu-wrapper .e-ul.e-menu-parent .e-menu-item.e-focused,
.e-menu-wrapper .e-ul.e-menu-parent .e-menu-item.e-selected,
.e-menu-wrapper .e-ul.e-menu-parent .e-menu-item.e-focused .e-menu-url,
.e-menu-wrapper .e-ul.e-menu-parent .e-menu-item.e-selected .e-menu-url {
    color: #007e7a;
    outline: none;
    background-color: transparent;
}


/*MENU MOBILE*/

.navbar-light .navbar-toggler {
    border: none;
    padding: 0;
}

#menuMobile.e-listview .e-list-header {
    background-color: transparent;
}

#menuMobile.e-listview .e-icon-wrapper .e-list-text,
#menuMobile.e-listview .e-list-text {
    color: #747876;
    font-size: 15px;
    line-height: 17px;
    font-family: MyriadPro;
    text-decoration: none;
}

#menuMobile.e-listview .e-list-item {
    background-color: transparent;
    height: 56px;
    line-height: 56px;
}

#menuMobile.e-listview .e-list-item.e-hover a.e-list-url {
    text-decoration: none;
}

#menuMobile.e-listview .e-list-icon {
    color: #747876;
    font-size: 18px;
    line-height: 30px;
    height: auto;
    width: auto;
}

#menuMobile.e-listview .e-icons:not(.e-icon-back) {
    color: #EDB111;
}

#menuMobile.e-listview .e-icons.e-icon-back {
    font-size: 18px;
    color: #747876;
}

.e-icons#close {
    position: absolute;
    top: 18px;
    right: 30px;
    font-size: 14px;
    color: #747876;
}

@media screen and (max-width: 767px) {
    .menuItemBox {
        display: none;
    }

    .adminMenu {
        padding: 8px 0 8px 10px;
    }

    .adminMenu .user {
        background-color: transparent;
        border-radius: 0;
        padding: 0;
    }

    .adminMenu .user span {
        display: none;
    }

    .adminMenu .user .img {
        margin: 0;
    }
    ul#menuAd:before {
        right: 20%;
    }

    header.geral nav.first #logo,
    header.geral nav.first .navbar-brand#title {
        display: none;
    }

    header.navbar-toggler-icon {
        outline: none;
    }

    header.geral nav.first .navbar-brand#initials {
        display: block;
        margin: 0;
        padding: 0;
        font-size: 22px;
        line-height: 28px;
        font-weight: 400;
        outline: none;
    }

    header.geral nav.first .navbar-brand#initials::before {
        content: none;
    }
    header.geral nav.second {
        position: fixed;
        top: 0;
        left: -100vw;
        width: 100vw;
        height: 100vh;
        z-index: 9;
        align-items: flex-start;
        transition: .4s;
    }

    header.geral nav.second.collapsed {
        left: 0;
        padding: 0;
    }

    header.geral .e-listview .e-list-header {
        padding: 0 16px;
    }

    header.geral nav.second.collapsed .container-fluid {
        align-items: flex-start;
    }

    header.geral .e-listview .e-text .e-headertext {
        font-size: 16px;
        /* color: #007E7A; */
    }

    header.geral #menuMobile.e-listview .e-icons.e-icon-back {
        padding-right: 10px;
    }
}
