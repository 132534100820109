/*----   LiaisonReport Page CSS   ----*/

#barOnTop {
    width: 100%;
    height: 75px;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
}

#leftContainer {
    margin-top: -90px;
}

/*----   Filter Component CSS   ----*/

@media print {
  body * {
    visibility: hidden;
  }
  #exportReport {
    display: none;
  }

  #reportComponent, #reportComponent * {
    visibility: visible;
  }
  #reportComponent {
    position: fixed;
    left: 0;
    top: 0;
  }
}

#filterComponent {
    margin-top: -45px;
    border: 1px solid;
    border-color: #e0e0e0;
    background-color: #fff;
    border-radius: 4px;
    padding: 6px 18px;
    margin-bottom: 10px;
}

#filterComponent .hrBar {
    color: #F7F9F9;
    background-color: #F7F9F9;
    border: 1px solid #F7F9F9;
    margin: 5px 0;
}

.labelOnTop {
    color: #007E7A;
    font-family: 'Caecilia';
    font-size: 28px;
    padding: 0 0 55px 15px;
}

#filterComponent .headerFilter {
    margin: 18px 0 10px 13px;
}

#filterComponent .headerFilter span.mdl2.mdl2-filter {
    font-size: 19px;
    padding-right: 12px;
    color: #007E7A;
    font-weight: 400;
}

#filterComponent .headerFilter label {
    font-family: 'Caecilia';
    font-size: 18px;
    color: #007E7A;
    font-weight: 700;
}

#filterComponent .input {
    padding: 6px 0;
}

#filterComponent .input label {
    color: #747876;
    font-size: 14px;
    font-family: "MyriadPro";
    margin: 0;
}

#filterComponent .button {
    display: flex;
    justify-content: center;
}

#filterComponent .button button {
    padding: 6px 130px;
    border: none;
    color: #fff;
    border-radius: 3px;
    background-color: #007E7A;
    font-family: "MyriadPro";
    margin: 14px 0px 21px;
    font-size: 14px;
    width: 100%;
    align-items: center;
    display: flex;
}

/*----   ReportComponent Component CSS   ----*/

@media screen and (max-width: 768px) {
    #reportComponent #rightTable {
        margin: 15px 0 !important;
        width: 100% !important;
    }

    #reportComponent {
        flex-direction: column;
    }

    #reportComponent #graph_svg {
        min-height: 465px !important;
    }
}

#reportComponent {
    display: flex;
}

#reportComponent .reportTopLabel {
    font-family: 'Caecilia';
    font-size: 18px;
    color: #007E7A;
    font-weight: 700;
    margin-top: 15px;
}

#reportComponent .dateDiv > div {
    align-items: center;
    display: flex;
    color: #57595B;
    font-size: 14px;
    font-family: "MyriadPro";
    margin-top: 10px;
}

#reportComponent .dateDiv > div span.mdl2.mdl2-calendar {
    font-size: 14px;
    color: #747876;
}

#reportComponent .dateDiv > div label {
    align-items: center;
    display: flex;
    color: #747876;
    font-size: 14px;
    margin: 0 0 0 8px;
}

#reportComponent .dateDiv > p {
    margin: 15px 0 15px 5px;
    color: #747876;
    font-family: "MyriadPro";
    font-size: 14px;
}

#reportComponent #graphAxisLabels0 text {

}

#reportComponent #graph_ChartAreaBorder,
#reportComponent #graphAxisLine_1 {
    display: none;
}

#reportComponent #rightTable {
        border: 1px solid;
    border-color: #e0e0e0;
    width: 350px;
    margin: 69px 10px auto 13px;
    height: 100%;
    border-bottom: none;
}

#reportComponent > div {
    width: 100%;
}

#reportComponent #rightTable .header {
    display: flex;
    background-color: #F7F9F9;
    color: #747876;
    border-bottom: 1px solid #e0e0e0;
    padding: 17px;
    justify-content: space-between;
}

#reportComponent #graph {
    overflow: auto !important;
}

#reportComponent #rightTable .dataRightTable {
    color: #747876;
    font-family: "MyriadPro";
}

#reportComponent #rightTable .dataRightTable {
    display: flex;
    background-color: #fff;
    font-size: 14px;
    padding: 8px 17px;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
}

#reportComponent #rightTable .dataRightTable label{
    margin: 0;
}

#reportComponent #rightTable .header label{
    color: #747876;
    font-family: "MyriadPro";
    font-size: 14px;
    margin: 0;
}

#reportComponent #graph_svg {
    min-height: 600px;
}

