/*Estilização de componentes do Syncfusion*/
#bannerItems .e-gridcontent tr.e-row td.e-rowcell:first-child {
    color: #19836e;
}

th.e-headercell.e-fltr-icon.e-mousepointer,
th.e-headercell.e-lastcell.e-defaultcursor {
    background-color: #f7f9f9;
}

span.e-filled.comboOutline {
    border: 1px solid rgba(0, 0, 0, 0.24) !important;
    border-radius: 4px !important;
    border-width: 1px !important;
    fill: transparent;
    transition: border .2s, box-shadow .2s !important;
}

.e-input-group.e-float-icon-left .e-input-in-wrap {
    border: none !important;
}

.e-gridpager.e-control.e-pager.e-lib {
    border: 1px solid #e0e0e0;
    border-width: 0 1px 1px 1px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.e-gridheader.e-lib.e-droppable {
    border: none;
}

.e-gridcontent.e-lib.e-droppable {
    border: 1px solid #e0e0e0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

div#gridcomp {
    border: none;
}

.busca .btnBusca.d-flex .btnFilter button {
    background-color: #f7f9f9;
    border-color: #fff;
    color: #007e7a !important;
}

.btnFilter .e-btn.e-outline.e-primary:active {
    background-color: #52a7a5 !important;
    border-color: transparent !important;
    box-shadow: none !important;
    color: #fff !important;
}

.btnFilter .e-btn.e-outline.e-primary:focus {
    background-color: #f7f9f9 !important;
    border-color: #ffffff !important;
    color: #007e7a !important;
    box-shadow: none !important;
}

.btnFilter .e-btn.e-outline.e-primary:hover {
    background-color: #f7f9f9 !important;
    border-color: #007e7a !important;
    color: #007e7a !important;
}

.busca .brnBusca.d-flex .btnSearch button {
    width: 88%;
    margin-left: 15px;
}

span.mdl2.mdl2-filter {
    font-size: 11px;
    padding: 0 5px 0px 0px;
    font-weight: 700;
}

/*Estilização de componentes do Syncfusion*/

span.mdl2.mdl2-search {
    font-weight: bold;
    padding-right: 10px;
}


/* */

.containerTop.banner {
    margin-top: -70px;
}

#bannerItems.e-grid {
    border: 0;
    margin-top: 30px;
}

#bannerItems .e-gridcontent {
    border: 1px solid #e0e0e0;
}

#bannerItems.e-grid .e-headercelldiv {
    font-size: 14px;
    line-height: 21px;
    font-family: 'MyriadPro', sans-serif;
    color: #747876;
    font-weight: 600;
}

#bannerItems.e-grid .e-gridcontent tr.e-row .e-rowcell {
    font-size: 14px;
    font-family: 'MyriadPro', sans-serif;
    color: #747876;
    font-weight: 400;
}

#bannerItems.e-grid .e-gridheader.e-lib.e-droppable {
    border: 0;
    background-color: transparent;
}

#bannerItems.e-grid .e-table {
    background-color: transparent;
}

#bannerItems.e-grid .e-headercell,
#bannerItems.e-grid .e-detailheadercell {
    background-color: transparent;
}

#bannerItems .status {
    font-size: 13px;
    line-height: 15px;
    font-family: 'MyriadPro', sans-serif;
    font-weight: 400;
    padding: 7px 34px;
    border-radius: 2px;
}

#bannerItems .status.sim {
    background-color: #E9F2DA;
    color: #69BE28;
}

#bannerItems .status.nao {
    background-color: #F6D6D2;
    color: #D20340;
}

#bannerItems .image img {
    height: 76px;
    margin: 10px 0;
}

#bannerItems .title {
    cursor: pointer;
    color: #007E7A;
    font-size: 14px;
    line-height: 17px;
    font-family: 'MyriadPro';
    font-weight: 400;
}

#modalBanners.e-dialog {
    min-height: 90%;
}

#modalBanners > div:first-child button {
    position: absolute;
    right: -15px;
    left: inherit;
    top: -15px;
}

#modalBanners .upload {
    border: 1px solid #F1F1F1;
    border-radius: 4px;
    padding: 9px 8px;
}

#modalBanners .upload > div {
    border: 1px dotted #D8CDCD;
    border-radius: 4px;
}

#modalBanners .upload > div.new {
    background-color: #F7F9F9;
    text-align: center;
    height: 245px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#modalBanners .upload > div img {
    width: 100%;
}

#modalBanners .upload > div p {
    color: #747876;
    font-size: 16px;
    line-height: 19px;
    font-family: 'MyriadPro';
    font-weight: 400;
    margin: 35px 0 0;
}

#modalBanners .upload > div p span {
    cursor: pointer;
    color: #007E7A;
}

#modalBanners .upload > div span#change {
    cursor: pointer;
    display: block;
}

#modalBanners .half {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#modalBanners .half > div {
    width: 48%;
}

#modalBanners .input {
    margin-top: 21px;
}

#modalBanners label:not(.e-error) {
    font-size: 14px;
    line-height: 21px;
    font-family: 'MyriadPro', sans-serif;
    color: #747876;
    font-weight: 600;
}

#modalBanners .e-upload {
    border: none;
}

#modalBanners .e-upload .e-file-select-wrap {
    display: none;
}

#modalBanners .e-upload .e-upload-files {
    background-color: #F7F9F9;
    border: 1px solid #F1F1F1;
}

#modalBanners .e-btn.e-flat.e-primary {
    width: 197px;
    color: #ffffff;
    background-color: #007e7a;
}

#modalBanners .bannerDelete {
    background-color: #F7F9F9 !important;
    color: #BB133E !important;
    float: left;
}

#modalBanners #validateImage {
    color: #ff0000;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
    font-size: 12px;
    font-weight: normal;
    padding-top: 4px;
}

#modalBanners .input label {
    margin: 0px;
}

#modalBanners {
    max-height: calc(100% - 50px) !important;
}

#loadBanner {
    margin-top: 60px;
}

#confirmDeleteModal {
    width: 446px;
}

#modalBanners .custom-e-upload .e-upload-file-list {
    display: flex;
    justify-content: space-between;
}

#modalBanners .custom-e-upload .e-file-name {
    overflow: unset;
}

#modalBanners .e-upload {
    display: none;
}

#modalBanners .custom-e-upload {
    display: block !important;
}

#modalBanners .custom-e-upload .e-upload-file-list button {
    border: 1px solid #007E7A;
    border-radius: 2px;
    padding: 6px 11px;
    color: #007E7A;
    margin-right: 10px;
    font-family: 'MyriadPro';
    font-size: 13px;
    background-color: #fff;
    height: 28px;
}

#modalBanners #loadingBanner {
    position: relative;
    height: 60px;
}

#modalBanners .removeMargin {
    margin-top: 0px;
}

.hidden {
    display: none !important;
}

@media screen and (max-width: 500px) {
    #modalBanners .half {
        flex-direction: column;
    }
    #modalBanners .half > div {
        width: 100%;
    }
    #modalBanners .e-footer-content .e-control.e-btn.e-lib.e-flat {
        width: 50%;
        margin: 0;
    }
    #modalBanners .custom-e-upload .e-upload-file-list {
        flex-wrap: wrap;
    }
    #modalBanners .custom-e-upload .e-upload-file-list .e-file-container {
        width: 100%;
        margin: 0 12px;
    }
    #modalBanners .custom-e-upload .e-upload-file-list button {
        margin-top: 20px;
        margin-left: auto;
    }
}