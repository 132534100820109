.caixaArrastar .e-grid {
	border: none;
}

#measurement .e-grid .e-headercontent {
	border: none;
}

#measurement .e-columnheader th {
	background-color: #F7F9F9;
}

#measurement .e-gridheader.e-lib.e-droppable {
	padding: 0!important;
}

#measurement .e-columnheader span {
	font-family: 'MyriadPro';
	font-size: 14px;
	color: #747876;
	font-weight: 400;
}

#measurement #measurementGrid td {
	font-family: 'MyriadPro';
	font-size: 14px;
	color: #747876;
	background-color: #fff;
	padding: 15px 21px;
}

#measurement #measurementGrid .e-groupcaption {
	font-family: 'MyriadPro';
	font-size: 14px;
	color: #747876;
	font-weight: 600;
	background-color: #fff;
}

#measurement .contrato {
	color: #007E7A;
}

#measurement .e-grid .e-icons {
    color: #747876;
}

#measurement #measurementGrid .e-summarycell {
	background-color: #F7F9F9;
}

#measurement #measurementGrid .sumClass{
	font-family: 'MyriadPro';
	font-size: 14px;
	color: #747876;
	font-weight: 600;
}

#measurement .e-gridcontent {
	border: 1px solid #e0e0e0;
}