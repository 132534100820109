#status.e-accordion {
    background-color: #ffffff;
    border: 1px solid #ececec;
    border-radius: 4px;
    margin-bottom: 30px;
}

#status.e-accordion .title {
    padding: 13px;
}

#signature:empty {
    display: none;
}

#status.e-accordion .title .mdl2 {
    color: #007e7a;
    font-size: 18px;
}

#status.e-accordion .title h2 {
    color: #007e7a;
    font-family: "Caecilia";
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 15px;
}

#status.e-accordion .e-toggle-icon {
    height: 65px;
    min-height: 65px;
    display: flex;
    align-items: center;
}

#status.e-accordion .e-tgl-collapse-icon {
    background-color: #f7f9f9;
    border-radius: 2px;
    padding: 6px 18px;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
}

#status.en.e-accordion .e-tgl-collapse-icon::after {
    content: "Show";
}
#status.pt.e-accordion .e-tgl-collapse-icon::after {
    content: "Mostrar";
}

#status.e-accordion .e-tgl-collapse-icon::after {
    font-family: MyriadPro;
    font-size: 14px;
    line-height: 14px;
    color: #747876;
    display: block;
    margin: 0 0 0 5px;
}

#status.e-accordion .e-tgl-collapse-icon.e-expand-icon {
    transform: rotate(0deg);
}

#status.e-accordion .e-tgl-collapse-icon::before {
    transition: all 0.3s;
}

#status.e-accordion .e-tgl-collapse-icon.e-expand-icon::before {
    transform: rotate(-180deg);
}

#status.en.e-accordion .e-tgl-collapse-icon.e-expand-icon::after {
    content: "Hide";
}

#status.pt.e-accordion .e-tgl-collapse-icon.e-expand-icon::after {
    content: "Esconder";
}

#status .e-acrdn-content {
    padding: 16px 0 0;
}

#status .e-gridcontent.e-lib.e-droppable {
    border-left: 0;
    border-right: 0;
}

#status .e-grid {
    border: 0;
}

.textBox {
    background-color: #ffffff;
    border: 1px solid #ececec;
    border-radius: 4px;
    padding: 0 18px 41px;
    margin: 0 0 30px;
}

.textBox .top {
    background-color: #f7f9f9;
    border-color: #ececec;
    border-width: 0 1px 1px;
    border-style: solid;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 8px 18px;
    margin: 0 0 20px;
}

.textBox .top .lang {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.textBox .top .e-label {
  font-family: MyriadPro;
  font-size: 14px;
  line-height: 14px;
  color: #747876;
}

.textBox .top .lang label.e-error {
    margin-left: 10px;
}

.textBox .top .e-input-group {
    max-width: 195px;
}

.textBox .top .e-checkbox-wrapper {
    margin-left: auto;
}

.textBox .top .e-filled.e-input-group,
.textBox .top .e-filled.e-input-group.e-control-wrapper,
.textBox .top .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.textBox .top .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) {
    background-color: #ffffff !important;
}

.textBox .box .e-filled.e-input-group,
.textBox .box .e-filled.e-input-group.e-control-wrapper,
.textBox .box .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.textBox .box .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) {
    background-color: #ffffff !important;
}

.textBox .input {
    margin: 0 0 18px;
}

.textBox .input label,
.textBox .e-input-group input.e-input,
.textBox .e-input-group.e-control-wrapper input.e-input,
.textBox .e-input-group textarea.e-input,
.textBox .e-input-group.e-control-wrapper textarea.e-input {
    color: #747876;
    font-size: 14px;
    line-height: 17px;
    font-family: MyriadPro;
    font-weight: 500;
}

.textBox .box {
    background-color: #f7f9f9;
    border-color: #ececec;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    padding: 8px 18px;
    margin: 0 0 20px;
}

.textBox .box .input .e-input-group {
    padding: 0 12px;
}

.textBox .box .input .e-input-group input {
    padding: 9px 0 !important;
}

.textBox .box .second .input {
    width: calc(100% / 2 - 20px / 1);
}

.textBox .input input#title {
    font-size: 14px;
    line-height: 17px;
    font-family: MyriadPro;
}

.textBox .input input#subtitle + textarea {
    font-size: 14px;
    line-height: 17px;
    font-family: MyriadPro;
}

.boxRichText {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 2px;
}

#release_rte-edit-view > p {
    display: none;
}

.boxRichText #release.e-richtexteditor.e-rte-tb-expand {
    width: 100% !important;
    margin: auto;
    border: none;
}

.boxRichText #release.e-richtexteditor.e-rte-tb-expand .e-rte-content,
.boxRichText #release.e-richtexteditor.e-rte-tb-expand .e-source-content {
    border: none;
}

.boxRichText #release.e-richtexteditor .e-rte-toolbar.e-control[class*="e-toolbar"],
.boxRichText #release.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-control[class*="e-toolbar"] {
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #ececec;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.changes {
    background-color: #ffffff;
    border: 1px solid #ececec;
    border-radius: 4px;
    margin-bottom: 30px;
}

.changes .top {
    padding: 18px;
    border-bottom: 2px solid #f7f9f9;
}

.changes .top .mdl2 {
    color: #007e7a;
    font-size: 18px;
}

.changes .top h2 {
    color: #007e7a;
    font-family: "Caecilia";
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 15px;
}

.changes .content {
    position: relative;
}

.changes .content:before {
    content: "";
    position: absolute;
    background-color: #ececec;
    width: 1px;
    top: 20px;
    left: 18px;
    bottom: 13px;
}

.changes .content > div {
    padding: 20px 18px 13px;
    max-height: 230px;
    overflow: auto;
}

.changes .content .item {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.changes .content .item:before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 1px solid #007e7a;
    background-color: #ffffff;
    top: 10px;
    left: -2px;
}

.changes .content .itemProfile:before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1px solid #007e7a;
  background-color: #ffffff;
  top: 30px;
  left: 15px;
}

.changes .content .item span {
    font-size: 14px;
    line-height: 17px;
    font-family: MyriadPro;
    color: #747876;
}

.changes .content .item .info {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.changes .content .item .info .name {
    font-weight: bold;
}

.changes .content .item .status {
    border: 1px solid #ececec;
    padding: 10px;
}

.listaReleases .e-grid .e-table,
.listaReleases .e-grid .e-gridheader,
.listaReleases .e-grid .e-headercell,
.listaReleases .e-grid .e-detailheadercell {
    background-color: transparent !important;
}

.listaReleases .e-grid .e-gridcontent tr.e-row td.e-rowcell:nth-child(2) span {
    color: #19836e;
    cursor: pointer;
}

.listaReleases .status {
    font-size: 13px;
    line-height: 15px;
    font-family: "MyriadPro", sans-serif;
    font-weight: 400;
    padding: 7px 34px;
    border-radius: 2px;
}

.listaReleases .status.sim {
    background-color: #e9f2da;
    color: #69be28;
}

.listaReleases .status.nao {
    background-color: #f6d6d2;
    color: #d20340;
}

#modalReleases > div:first-child button {
    position: absolute;
    right: -15px;
    left: inherit;
    top: -15px;
}

#gridRelease .e-row {
    width: 1305px;
    height: 38px;
}

#gridRelease .e-row td {
    color: #787c7a;
    font-family: "MyriadPro", sans-serif;
    font-size: 13px;
}

#gridRelease .e-row td .dataGroup {
    color: #787c7a;
    padding: 4px 8px;
    background-color: #f7f9f9;
    margin: 0 3px;
}

#gridRelease .e-row td .dataSuccess {
    color: #69be28;
    background-color: #e9f2da;
    padding: 2px 0;
    display: block;
    text-align: center;
}

#gridRelease .e-row td .dataErro {
    color: #d20340;
    background-color: #f6d6d2;
    padding: 2px 0;
    display: block;
    text-align: center;
}

#gridRelease .e-row td:nth-child(1) {
    font-weight: 600;
}

#gridRelease .e-row td:nth-child(4) {
    text-align: center;
    display: flex;
    padding-right: 0;
    padding-left: 0;
}

#gridRelease {
    cursor: pointer;
}

#gridModalRelease {
    border: none;
    padding-top: 25px;
}

#gridModalRelease td {
    padding: 20px;
    color: #747876;
    font-size: 13px;
}

#modalReleases_dialog-content {
    padding: 0;
}

#modalReleases .status {
    color: #69be28;
    background-color: #e9f2da;
    padding: 2px 0;
    display: block;
    text-align: center;
}

#modalReleases .status.success {
    color: #69be28;
    background-color: #e9f2da;
}

#modalReleases .status.error {
    color: #d20340;
    background-color: #f6d6d2;
}

#modalReleases .status.processing {
    color: #edb111;
    background-color: #fbefcf;
}

#modalReleases .mailingReport {
    color: #007e7a;
    font-size: 16px;
    font-family: "Caecilia";
    font-weight: 600;
    padding-left: 17px;
}

/* Envio de conteudo */
#dlgEmails .header {
    color: #007e7a;
    font-size: 16px;
    font-family: "Caecilia";
    font-weight: 600;
    padding-left: 17px;
}

#dlgEmails > div:first-child button {
    position: absolute;
    right: -15px;
    left: inherit;
    top: -15px;
}
#dlgEmails #gridcomp {
    margin-top: 18px;
}

#dlgEmails #gridcomp .e-row td .dataGroup {
    color: #787c7a;
    padding: 4px 8px;
    background-color: #f7f9f9;
}

#dlgEmails .e-grid .e-gridcontent tr.e-row td.e-rowcell:first-child {
    color: #19836e;
}

#gridRelease .e-pager .e-currentitem,
.e-pager .e-currentitem:hover {
    background: #007e7a !important;
}

#loadRelease {
    margin-top: 120px;
}
#gridMedia table {
    width: auto;
}

#customTbarDlg > div:first-child button {
    position: absolute;
    right: -15px;
    left: inherit;
    top: -15px;
}

#customTbarDlg .e-btn.e-flat.e-primary {
    width: 197px;
    color: #ffffff;
    background-color: #007e7a;
}

#customTbarDlg .e-btn.e-flat.e-primary:disabled {
    color: #a6a6c4;
    background-color: #e0e0e0;
}

#customTbarDlg .input {
    margin: 21px 0 0;
}

#customTbarDlg label:not(.e-error) {
    font-size: 14px;
    line-height: 21px;
    font-family: "MyriadPro", sans-serif;
    color: #747876;
    font-weight: 600;
}

#customTbarDlg .e-error {
    margin: 2px 0;
}

#customTbarDlg .fileInfo {
    font-size: 11px;
    line-height: 17px;
    margin: 2px 0;
    color: #747876 !important;
}

#customTbarDlg .fileUpload {
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    padding: 9px 8px;
}

#customTbarDlg .fileUpload > div {
    border: 1px dotted #d8cdcd;
    border-radius: 4px;
}

#customTbarDlg .fileUpload .new {
    background-color: #f7f9f9;
    text-align: center;
    height: 245px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#customTbarDlg .fileUpload > div p {
    color: #747876;
    font-size: 16px;
    line-height: 19px;
    font-family: "MyriadPro";
    font-weight: 400;
    margin: 35px 0 0;
}

#customTbarDlg .fileUpload > div p span {
    cursor: pointer;
    color: #007e7a;
}

#customTbarDlg .e-upload {
    border: none;
}

#customTbarDlg .e-upload .e-file-select-wrap {
    display: none;
}

#customTbarDlg .e-upload .e-upload-files {
    background-color: #f7f9f9;
    border: none;
}

#customTbarDlg .e-upload .e-upload-files .e-upload-file-list {
    border: none;
}

#customTbarDlg .e-upload .e-upload-files .e-upload-file-list .e-file-container .e-file-name,
#customTbarDlg .e-upload .e-upload-files .e-upload-file-list .e-file-container .e-file-type {
    font-family: "MyriadPro";
    font-size: 14px;
    opacity: 0.75;
}

#customTbarDlg .thumb-btnGenerate {
    margin: 0;
    width: 80px;
    height: 28px;
    color: #fff;
    background-color: #007e7a;
    font-size: 13px;
    font-weight: 400;
    border: none;
    position: absolute;
    right: 25px;
    bottom: 75px;
    border-radius: 0;
}

.boxRichText .e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
    top: -35px;
}

.successMessenge {
    display: block;
    padding: 10px 10px 10px 60px;
    border-radius: 2px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
    background: #007e7a;
    margin: 10px 0;
    color: #ffffff;
    position: relative;
}

.successMessenge:before {
    content: "\e8fb";
    font-family: "Fabric External MDL2 Assets";
    font-style: normal;
    font-size: 22px;
    text-rendering: auto;
    position: absolute;
    top: 5px;
    left: 13px;
}

.successMessenge:after {
    content: "";
    position: absolute;
    width: 3.5em;
    top: 0;
    left: 0;
    bottom: 0;
    right: auto;
    background-color: black;
    opacity: 0.2;
}

.boxRichText .e-rte-image.e-imginline {
    vertical-align: middle !important;
}

#btnReset.e-btn {
    width: 100%;
}

#btnReset.e-btn .mdl2.mdl2-reset {
    font-size: 15px;
    vertical-align: bottom;
}

#btnReset.e-btn .mdl2.mdl2-reset::before {
    font-weight: 600;
}

#btnVale.e-btn {
    padding-top: 4px;
    padding-left: 7px;
    padding-right: 2.5px;
    border-radius: 0;
    background: #007e7a;
    color: #fff;
}

#btnVale.e-btn span:not(.e-icons) {
    display: inline-block;
    min-width: 30px;
}

#btnVale.e-dropdown-btn .e-btn-icon {
    margin: 0;
    min-width: 24px;
    width: auto;
    display: inline-block;
    color: #fff;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item #btnVale.e-dropdown-btn.e-active {
    border-color: transparent;
}

@media screen and (min-width: 568px) {
    .textBox .top {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .textBox .box .first,
    .textBox .box .second {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
    .textBox .box .first .input {
        width: calc(100% / 4 - 30px / 0.5);
    }

    .first:first-child .input:first-child {
        width: 300px;
    }
}

.divTrash.complete {
    background-color: rgba(105, 190, 40, 0.2) !important;
}

.divTrash.complete .mdl2-confirm {
    color: #69be28;
    font-size: 38px;
    padding: 8px 0 0 10px;
}

.deleteComplete.complete .confirmTrash {
    background-color: rgba(105, 190, 40, 0.2) !important;
}

.deleteComplete.complete label {
    color: #69be28 !important;
}

.deleteComplete.complete .mdl2-confirm {
    color: #69be28;
    font-size: 64px;
    padding: 12px 0 0 5px;
    margin: 0 auto;
}

.divTrash.complete label.confirm {
    color: #69be28;
    font-weight: 700;
    font-family: "Caecilia";
    font-size: 18px;
}

/* forwardModal */

#forwardModal {
  width: 400px;
}

#forwardModal_dialog-content {
  padding: 18px 0;
}

#forwardModal hr {
  margin: 15px 0 0;
}

.divCopy .scroll-horizontal {
  overflow: auto;
  max-height: 200px;
  margin: 0 18px;
}

.divCopy .scroll-horizontal .selected{
  background-color: #eeeeee;
}

.divCopy .scroll-horizontal div {
  padding: 5px;
  margin: 2px 0;
}

.divCopy .scroll-horizontal div p {
  margin: 0;
  font-family: 'Caecilia';
  font-size: 14px;
}

.divCopy .scroll-horizontal div:hover {
  background-color: #eeeeee;
  cursor: pointer;
}

#forwardModal .footerButtons {
  display: flex;
  justify-content: space-around;
  padding: 19px 0 0 0;
}

#forwardModal .footerButtons button {
  width: 198px;
  height: 28px;
  border: none;
}

#forwardModal .footerButtons button:first-child {
  background-color: #F7F9F9;
  color: #747876;
  margin: 0;
  margin-right: 8px !important;
  margin-left: 18px !important;
}

#forwardModal .footerButtons button:last-child {
  background-color: #007E7A;
  color: #fff;
  margin: 0;
  margin-right: 18px !important;
  margin-left: 8px !important;
}

/* ========= */

@media screen and (max-width: 568px) {
  .textBox .box .second .input {
    width: 100%;
  }
  .textBox .top .e-checkbox-wrapper {
    margin-top: 15px;
  }
}
