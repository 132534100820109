/* .liaisonData {
    background-color: #FFFFFF;
    border-radius: 4px;
    margin-bottom: 30px;
} */

.liaisonData.item {
    padding: 0 21px 5px;
    border: 1px solid #ececec;
    background-color: #ffffff;
}

.liaisonData .e-grid.e-resize-lines .e-headercell .e-rhandler {
    border: none;
}

.liaisonData .top {
    background-color: #f7f9f9;
    border: 1px solid #f1f1f1;
    text-align: center;
    padding: 0 23px 23px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-bottom: 47px;
}

.liaisonData .top .av {
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ececec;
    border-radius: 50%;
    margin: 10px auto -70px;
    width: 90px;
    height: 90px;
    overflow: hidden;
    position: relative;
}

.liaisonData .status {
    font-size: 13px;
    line-height: 15px;
    font-family: "MyriadPro", sans-serif;
    font-weight: 400;
    padding: 6px 34px;
    border-radius: 2px;
    width: 201px;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
}

.liaisonData .status.green {
    background-color: #e9f2da;
    color: #69be28;
}

.liaisonData .status.orange {
    background-color: #fee7d2;
    color: #ee7518;
}

.liaisonData .status.red {
    background-color: #f6d6d2;
    color: #d20340;
}

.liaisonData .top .av::after {
    content: "";
    position: absolute;
    width: 89px;
    height: 89px;
    background-color: #f7f9f9;
    top: 0;
    left: 0;
    z-index: 0;
    border-radius: 50%;
    border: 6px solid #fff;
}

.liaisonData .top .av .icon {
    position: absolute;
    z-index: 1;
    width: 66px;
    height: 66px;
    background-color: #fff;
    border-radius: 50%;
    top: 11px;
    left: 12px;
    border: 1px solid #ececec;
    display: flex;
    justify-content: center;
    align-items: center;
}

.liaisonData label {
    color: #747876;
    font-family: "MyriadPro";
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
}

.liaisonData .input,
.liaisonData .checkItem {
    margin-bottom: 19px;
}

.liaisonData .checkItem .e-wrapper .e-label {
    font-size: 14px;
    line-height: 17px;
    color: #747876;
}

.liaisonData .e-grid .e-gridcontent tr.e-row td.e-rowcell:nth-child(2) span {
    color: #19836e;
    cursor: pointer;
}

.liaisonData .hide {
    display: none;
}

.liaisonData .input.scroll-horizontal {
    overflow: auto;
    max-height: 310px;
}

.liaisonData .scroll-horizontal #telefone {
    position: relative;
    padding: 9px 0 9px 2.3rem;
}

.liaisonData .scroll-horizontal .dropDownList {
    width: 100%;
    height: auto;
    max-height: 10rem;
    padding: 0 0 .5rem 0;
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 2rem;
    overflow-y: scroll;
    background: #fff;
    border: 1px solid #ececec;
    margin: 0;
    z-index: 1;
}

.liaisonData .scroll-horizontal .dropDownList .inputFlags {
  width: 90%;
  margin: .5rem 0;
  padding: 9px 0;
  color: #747876;
  font-size: 14px;
  line-height: 17px;
  font-family: "MyriadPro";
  border: 0;
  border-bottom: 1.5px solid #DEDFDE;
  min-height: 30px;
  align-self: center;
  outline: thin dotted;
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: 0px;
}

.liaisonData .scroll-horizontal .dropDownList .inputFlags:hover {
  border-bottom: 1.5px solid #949494;
}

.liaisonData .scroll-horizontal .containerCountry {
    width: min-content;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    bottom: 2.2rem;
    z-index: 1;
}

.liaisonData .scroll-horizontal .flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    margin: 0;
    border-radius: 0;
    background: #fff;
}

.liaisonData .scroll-horizontal .flex:hover {
    background: #E2E2E2;
}

.liaisonData .scroll-horizontal .countryFlag {
    width: 22px;
}

.liaisonData .scroll-horizontal .countryName {
    margin: 0 0 0 6px;
}

.liaisonData .scroll-horizontal .containerCountry .countryIcon {
    font-size: 10px;
}

.liaisonData .input .addLine {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.liaisonData .input .mdl2::before {
    font-size: 10px;
}

.liaisonData .input .mdl2 {
    background: #f7f9f9;
    line-height: 28px;
    min-width: 28px;
    border-radius: 4px;
    text-align: center;
    color: #007e7a;
    cursor: pointer;
    font-size: 10px;
}

.liaisonData .input .mdl2-minus {
    float: right;
    position: relative;
    bottom: 2.5rem;
}

.liaisonInfo {
    background-color: #ffffff;
    border: 1px solid #ececec;
    border-radius: 4px;
    margin-bottom: 30px;
}

.liaisonInfo label {
    color: #747876;
    font-family: "MyriadPro";
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
}

.liaisonInfo .top {
    padding: 18px;
}

.liaisonInfo .top h2 {
    color: #007e7a;
    font-family: "Caecilia";
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 15px;
}

.liaisonInfo .top .mdl2 {
    color: #007e7a;
    font-size: 18px;
}

.liaisonInfo .featured {
    padding: 18px;
    border-top: 4px solid #f7f9f9;
    border-bottom: 1px solid #ececec;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.liaisonInfo span {
    padding-left: 5px;
}

.liaisonInfo .featured .input {
    width: calc(100% / 2 - 18px);
}

.liaisonInfo .featured .input .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.liaisonInfo
    .featured
    .input
    .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
.liaisonInfo
    .featured
    .input
    .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.liaisonInfo
    .featured
    .input
    .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
.liaisonInfo
    .featured
    .input
    .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
.liaisonInfo
    .featured
    .input
    .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap {
    background-color: #f7f9f9;
}

.liaisonInfo .description {
    padding: 18px;
    background-color: #f7f9f9;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.liaisonInfo .description .input {
    width: calc(100% / 2 - 18px);
    margin-bottom: 19px;
}

.liaisonInfo .description .input.x2 {
    width: 100%;
}

.liaisonInfo .description .input .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.liaisonInfo
    .description
    .input
    .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
.liaisonInfo
    .description
    .input
    .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.liaisonInfo
    .description
    .input
    .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
.liaisonInfo
    .description
    .input
    .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
.liaisonInfo
    .description
    .input
    .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap {
    background-color: #fff;
}

.responsible {
    background-color: #ffffff;
    border: 1px solid #ececec;
    border-radius: 4px;
    margin-bottom: 30px;
}

.responsible .top {
    padding: 18px;
    border-bottom: 2px solid #f7f9f9;
}

.responsible .top .mdl2 {
    color: #007e7a;
    font-size: 18px;
}

.responsible .top h2 {
    color: #007e7a;
    font-family: "Caecilia";
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 15px;
}

.responsible .content {
    padding: 20px 18px 13px;
}

.responsible .content label {
    color: #747876;
    font-family: "MyriadPro";
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
}

.attachments {
    background-color: #ffffff;
    border: 1px solid #ececec;
    border-radius: 4px;
    margin-bottom: 30px;
}

.attachments .top {
    padding: 18px 18px 0;
}

.attachments .top .mdl2 {
    color: #007e7a;
    font-size: 18px;
}

.attachments .top h2 {
    color: #007e7a;
    font-family: "Caecilia";
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 15px;
}

.attachments .content {
    padding: 20px 18px 13px;
}

.attachments .content .uploader {
    background-color: #f7f9f9;
    text-align: center;
    padding: 17px;
    border: 2px dashed #f1f1f1;
    border-radius: 4px;
}

.attachments .content .uploader p {
    color: #747876;
    font-size: 16px;
    line-height: 19px;
    font-family: "MyriadPro";
    font-weight: 400;
    margin: 0;
}

.attachments .content .uploader p span {
    cursor: pointer;
    color: #007e7a;
}

.attachments .e-upload .e-file-select-wrap {
    display: none;
}

.attachments .e-upload {
    border: none;
}

.pointerClick {
    cursor: pointer;
}

#loadingItem .e-spinner-pane.e-spin-show {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: #dedede80;
}

@media screen and (max-width: 400px) {
    .liaisonInfo .featured {
        flex-direction: column;
    }
    .liaisonInfo .featured .input {
        width: 100%;
    }
    .liaisonInfo .description .input {
        width: 100%;
    }
    .liaisonData .input .mdl2-minus {
        bottom: 2.9rem;
    }
}
