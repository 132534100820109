.e-ptBr::before {
    content: url(../../css/imgs/brazil.png);
}

.e-enUS::before {
    content: url(../../css/imgs/united-states.svg);
}

.e-btn.choosenLanguage {
    color: #747876;
    font-size: 12px;
    line-height: 14px;
    font-family: MyriadPro;
    font-weight: 400;
    background-color: #F7F9F9;
    border-radius: 4px;
    min-width: 131.8px;
    margin: 0 5px;
}

.e-dropdown-btn:hover,
.e-dropdown-btn:focus,
.e-dropdown-btn.e-btn:hover,
.e-dropdown-btn.e-btn:focus {
    box-shadow: none !important;
}

.choosenLanguage.e-btn.e-active {
    background-color: #F7F9F9;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px 3px 6px #00000029;
}

.e-dropdown-popup .e-item#pt,
.e-dropdown-popup .e-item#en {
}

#languageDropdown {
    padding: 0;
    background-color: #F7F9F9;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0px 3px 6px #00000029;
    min-width: 131.8px;
}


#languageDropdown::before {
    content: "";
    position: absolute;
    top: -2px;
    width: 100%;
    height: 5px;
    background-color: #f7f9f9;
}

#languageDropdown .e-item {
    align-items: center;
    font-family: "MyriadPro";
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #747876;
    padding: 6px 12px 4px;
}

#languageDropdown .e-item span {
    font-size: 10px;
    line-height: 10px;
    width: 2.25em;
}