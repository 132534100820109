.groupItem {
    background-color: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    margin-bottom: 30px;
}

.groupItem .top {
    padding: 18px;
    border-bottom: 2px solid #F7F9F9;
}

.groupItem .top .mdl2 {
    color: #007E7A;
    font-size: 18px;
}

.groupItem .top h2 {
    color: #007E7A;
    font-family: 'Caecilia';
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 15px;
}

.e-row .e-rowcell span.nameTemplate {
    color: #007E7A;
    cursor: pointer;
}


.groupItem .content .line .input label {
    color: #747876;
    font-family: "MyriadPro";
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
}

.groupItem .content .line {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 18px;
}

.groupItem .content .line.x2 {
    flex-direction: row;
}

.groupItem .content .line .input {
    width: 100%;
}

.groupItem .content .line.x2 .input {
    width: calc(100%/2  - 22px);
}

.groupItem .content .line .radio {
    background-color: #F7F9F9;
    border: 1px solid #ECECEC;
    border-radius: 2px;
    padding: 18px 19px;
}

.groupItem .content .line .radio p {
    font-family: 'MyriadPro';
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #747876;
}

.groupItem .content .line .radio ul {
    list-style: none;
    padding: 0;
}

.groupItem .content .line .radio ul li:not(:last-child) {
    margin: 0 0 11px;
}

.groupItem .content .line .radio ul li .e-label {
    font-size: 14px;
    line-height: 17px;
    font-family: 'MyriadPro';
    color: #747876;
    font-weight: 500;
}

@media screen and (max-width: 500px) {
    .groupItem .content .line.x2 .input {
        width: 100%;
    }
}
