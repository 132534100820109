#messageModal {
  width: 446px;  
}
.resett{
  max-height: 400px !important;
}
#messageModal_dialog-content {
  padding: 0;
}

#messageModal .e-footer-content {
  display: flex;
  justify-content: space-between;
  padding-bottom: 18px;
}

#messageModal .e-footer-content button:last-child {
  width: 197px;
  color: #ffffff;
  background-color: #007e7a;
}

#messageModal .e-footer-content button:first-child {
  width: 197px;
  color: #747876;
  background-color: #F7F9F9;
}

#messageModal .divTrash {
  background-color: #E9F2DA;
  display: flex;
  border-radius: 50%;
  width: 55px;
  text-align: center;
  height: 55px;
  margin: 18px 18px 0;
}
#messageModal .divTrash.red {
  background-color: #F6D6D2;}


#messageModal .divTrash .mdl2{
  color: #69be28;
  font-size: 38px;
  padding: 8px 0 0 10px;
}
#messageModal .divTrash .error.mdl2{
  color: #BB133E;
  font-size: 25px;
  padding: 15px 0 0 15px;
}

#messageModal .messageComplete .confirmTrash .success.mdl2 {
  color: #69be28;
  font-size: 64px;
  padding: 12px 0 0 5px;
  margin: 0 auto;
}

#messageModal .messageComplete .confirmTrash .error.mdl2 {
  font-size: 40px;
  padding: 26px 0 0 2px;
  color: #BB133E;
  margin: 0 auto;
}


#messageModal .messageComplete {
  display: flex;
  flex-direction: column;
}

#messageModal .messageComplete label {
  margin: 18px auto;
  color: #69be28;
  font-family: 'Caecilia';
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  word-break: break-word;
}


#messageModal .messageComplete button {
  width: 412px;
  height: 28px;
  border: none;
  color: #747876;
  background-color: #f7f9f9;
  margin: 5px auto 25px;
  cursor: pointer !important;
}
.footerButtons button{
  margin: auto !important;
}

#messageModal .confirmTrash {
  background-color: #E9F2DA;
  display: flex;
  border-radius: 50%;
  width: 107px;
  text-align: center;
  height: 107px;
  border: solid #f7f9f9;
  border-width: 8px;
  margin: 17px auto 0;
}
#messageModal .confirmTrash.red {
  background-color: #F6D6D2;
}

#messageModal .confirmTrash button{
  background-color: #F6D6D2;
  display: flex;
  border-radius: 50%;
  width: 107px;
  text-align: center;
  height: 107px;
  border: solid #f7f9f9;
  border-width: 8px;
  margin: 0 auto;
}

#messageModal .phraseModal {
  margin-left: 55px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  min-width: 322px;
  text-align: initial;
}

#messageModal #title {
  color: #69be28;
  font-weight: 700;
  font-family: 'Caecilia';
  font-size: 18px;
}
.red{
  color: #BB133E !important;
}

#messageModal #labelDelete , #messageModal #secondLabel{
  font-family: 'Caecilia';
  font-size: 14px;
  color: #747876;
}

#messageModal hr {
  margin: 15px 0 0;
}

#loadBanner {
  margin-top: 60px;
}

#confirmmessageModal {
  width: 446px;
}

#messageModal .footerButtons {
  display: flex;
  justify-content: space-around;
  padding: 19px 0;
}

#messageModal .footerButtons button {
  width: 198px;
  height: 28px;
  border: none;
}

#messageModal .footerButtons button:first-child {
  background-color: #F7F9F9;
  color: #747876; 
}

#messageModal .footerButtons button:last-child {
  background-color: #007E7A;
  color: #fff;
}
.footerButtons button.reproveButton{
  background-color: #F6D6D2 !important;
  color: #D20340 !important; 
}