#WhoIsWhoContent .nameTemplateDiv {
	display: flex;
    align-items: center;
}

#WhoIsWhoContent .nameTemplateDiv img {
	width: 46px;
	height: 46px;
	border-radius: 50%;
}

#WhoIsWhoContent .nameTemplateDiv label {
	font-family: 'MyriadPro';
    font-size: 13px;
    margin: 0;
	color: #007E7A;
	text-overflow: ellipsis;
    margin-left: 14px;
	overflow: hidden;
	cursor: pointer;
}

#WhoIsWhoContent .superiorTemplateDiv {
	display: flex;
    align-items: center;
}

#WhoIsWhoContent .superiorTemplateDiv a {
	display: flex;
    align-items: center;
}

#WhoIsWhoContent .superiorTemplateDiv img {
	width: 26px;
	height: 26px;
	border-radius: 50%;
}

#WhoIsWhoContent .superiorTemplateDiv label {
	font-family: 'MyriadPro';
    font-size: 13px;
	margin: 0;
	text-overflow: ellipsis;
    margin-left: 14px;
	overflow: hidden;
	cursor: pointer;
	color: #747876;
}

#WhoIsWhoContent .busca {
	border-radius: 0;
}

#WhoIsWhoContent .e-grid .e-headercontent {
	border: none;
}

#WhoIsWhoContent .e-columnheader th {
	background-color: #F7F9F9;
}

#WhoIsWhoContent .e-gridheader.e-lib.e-droppable {
	padding: 0!important;
}

#WhoIsWhoContent .e-columnheader span {
	font-family: 'MyriadPro';
	font-size: 14px;
	color: #747876;
	font-weight: 600;
}

#WhoIsWhoContent #whoiswhoGrid td {
	font-family: 'MyriadPro';
	font-size: 14px;
	color: #747876;
	background-color: #fff;
	padding: 23px 19px;
}

#WhoIsWhoContent #whoiswhoGrid .e-groupcaption {
	font-family: 'MyriadPro';
	font-size: 14px;
	color: #747876;
	font-weight: 600;
	background-color: #fff;
}

#WhoIsWhoContent .e-grid .e-groupheadercell {
    margin-left: 5px;
	background-color: #fff;
    border-radius: 2px;
}

#WhoIsWhoContent .e-grid .e-groupdroparea .e-icons {
    color: #BEBEBE;
}

#WhoIsWhoContent .contrato {
	color: #007E7A;
}

#WhoIsWhoContent .e-grid .e-icons {
    color: #747876;
}

#WhoIsWhoContent #whoiswhoGrid .e-summarycell {
	background-color: #F7F9F9;
}

#WhoIsWhoContent #whoiswhoGrid .sumClass{
	font-family: 'MyriadPro';
	font-size: 14px;
	color: #747876;
	font-weight: 600;
}

.phoneTemplateDiv {
	display: flex;
    flex-flow: column wrap;
}

.phoneTemplateDiv span {
	text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
}