/*Estilização de componentes do Syncfusion*/
.nomeUsuarios {
    cursor: pointer;
}

.listaUsuarios .e-grid .e-headercell,
.listaUsuarios .e-grid .e-detailheadercell {
    background-color: #f7f9f9;
}

#formProfileTemplate .e-grid .e-gridcontent tr.e-row .e-rowcell,
.permissions .e-grid .e-gridcontent tr.e-row .e-rowcell {
    overflow: visible;
    white-space: pre-wrap;
}

#loadUsersList {
    margin-top: 60px;
}

.listaUsuarios .e-grid .e-gridcontent tr.e-row td.e-rowcell:first-child span {
    color: #19836e;
    cursor: pointer;
}

.changePassword .content > button {
    margin-bottom: 4%;
}

.btnBusca span.mdl2.mdl2-filter {
    font-size: 11px;
    padding: 0 5px 0px 0px;
    font-weight: 700;
}

.e-input-group.e-control-wrapper:hover input.e-input,
.e-filled.e-input-group:not(.e-float-input) input.e-input,
.e-filled.e-input-group:not(.e-float-input).e-control-wrapper input.e-input {
    border: none;
}

.e-filled.e-input-group:not(.e-float-input) input.e-input,
.e-filled.e-input-group:not(.e-float-input).e-control-wrapper input.e-input::placeholder {
    color: rgba(0, 0, 0, 0.42);
}

span.e-input-group-icon.e-ddl-icon.e-search-icon {
    color: rgba(0, 0, 0, 0.42);
}

.users .filtrosOcultos .e-float-input.e-control-wrapper {
    background-color: #fff;
    margin: 2.5px;
}

.users .filtrosOcultos .e-float-input:not(.e-input-group) input,
.users .filtrosOcultos .e-float-input.e-control-wrapper:not(.e-input-group) input {
    padding: 9px 0 9px 0;
}

.users .filtrosOcultos span {
    margin-left: 5px;
}

.users .filtrosOcultos .e-float-input input:focus ~ label.e-float-text,
.users .filtrosOcultos .e-float-input input:valid ~ label.e-float-text,
.users .filtrosOcultos .e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
.users .filtrosOcultos .e-float-input.e-control-wrapper input:valid ~ label.e-float-text {
    top: 0px !important;
}

.users .filtrosOcultos label.e-float-text,
.users .e-float-input label.e-float-text,
.users .filtrosOcultos .e-float-input.e-control-wrapper label.e-float-text,
.users .filtrosOcultos .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
.users .filtrosOcultos .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    left: 7px;
    top: -5px !important;
}

/*Estilização de componentes do Syncfusion*/

span.mdl2.mdl2-search {
    font-weight: bold;
    padding-right: 10px;
}

.nomeFornecedores {
    cursor: pointer;
    color: #19836e;
}

/* Permissions CSS */
.permissions {
    background-color: #fff;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    margin-bottom: 30px;
}

.permissions .top {
    padding: 18px;
    border-bottom: 2px solid #F7F9F9;
}

.permissions .top .mdl2 {
    color: #007E7A;
    font-size: 18px;
}

.permissions .top h2 {
    color: #007E7A;
    font-family: 'Caecilia';
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 15px;
}

.permissions .top .checkbox {
    margin-left: auto;
}

.permissions .top .checkbox .e-checkbox-wrapper.e-checkbox-disabled .e-label,
.permissions .top .checkbox .e-css.e-checkbox-wrapper.e-checkbox-disabled .e-label {
    color: #747876;
    border-color: #007E7A;
}

.permissions .top .checkbox .e-checkbox-wrapper.e-checkbox-disabled .e-frame,
.permissions .top .checkbox .e-css.e-checkbox-wrapper.e-checkbox-disabled .e-frame {
    border-color: #707070;
}

.permissions .top .checkbox .e-checkbox-wrapper.e-checkbox-disabled .e-frame.e-check,
.permissions .top .checkbox .e-css.e-checkbox-wrapper.e-checkbox-disabled .e-frame.e-check {
    background-color: #007E7A;
    border-color: #007E7A;
}

.permissions .input {
    width: calc(100%/3 - 20px/3);
}

.permissions .one .input {
    width: 100%;
}

.permissions .two .input {
    width: calc(100%/2 - 20px/2);
}

.permissions .input label {
    color: #747876;
    font-family: "MyriadPro";
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
}
.permissions .one {
    background-color: #F7F9F9;
    border-bottom: 1px solid #ECECEC;
}

.permissions .one .e-filled {
    background-color: #FFFFFF !important;
}

.permissions .one,
.permissions .two {
    padding: 20px 18px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.permissions .two.inverse {
    background: #fff;
}

.permissions .two.inverse .e-filled.e-input-group,
.permissions .two.inverse .e-filled.e-input-group.e-control-wrapper,
.permissions .two.inverse .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.permissions .two.inverse  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left){
    background-color: #F7F9F9 !important;
}

.permissions .divButton {
    width: 100%;
    padding: 20px 18px;
    display: inline-block;
}

.permissions .divButton button {
    float: right;
    border: 1px solid #007E7A;
    color: #007E7A;
    padding: 10px 28px;
    background-color: #fff;
    box-shadow: none;
}

.permissions span.permit {
    color: #747876;
    font-size: 13px;
    line-height: 15px;
}

.permissions span.permit:not(:last-child):after {
    content: ",";
    display: inline-block;
    margin-right: 4px;
}

.listaUsuarios .sim {
  background-color: #e9f2da;
  color: #69be28;
  font-size: 13px;
  line-height: 15px;
  font-family: "MyriadPro", sans-serif;
  font-weight: 400;
  padding: 7px 34px;
  border-radius: 2px;
}

.permissions #gridcomp .e-gridcontent {
    border: none;
}

.permissions #gridcomp .e-headercontent {
    border-bottom: 1px solid #ECECEC;
}

/* Permissions Fim */
/* NewPassword CSS */
.changePassword {
    background-color: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    margin-bottom: 30px;
}

.changePassword .top {
    padding: 18px;
    border-bottom: 2px solid #F7F9F9;
}

.changePassword .top h2 {
    color: #007E7A;
    font-family: 'Caecilia';
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 15px;
}

.changePassword .top .mdl2 {
    color: #007E7A;
    font-size: 18px;
}

.changePassword .content {
    padding: 20px 18px 13px;
}

.changePassword .content .input {
    margin-bottom: 20px;
}

.changePassword .content .input label {
    color: #747876;
    font-family: "MyriadPro";
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
}

.changePassword form span {
    color: #007E7A;
    text-align: center;
    font-size: 14px;
}

.e-multi-select-wrapper{
    height: 38px;
}

/* NewPassword Fim */

@media screen and (max-width: 500px) {
    .permissions .two .input {
        width: 100%;
    }
}
