#logsErros.e-grid {
  border: 0;
  /* margin-top: 30px; */
}

#logsErros.e-grid .e-gridcontent tr.e-row .e-rowcell {
  font-size: 14px;
  font-family: "MyriadPro", sans-serif;
  color: #747876;
  font-weight: 400;
}

#logsErros .e-gridcontent tr.e-row td.e-rowcell:first-child {
  color: #19836e;
}

#logsErros.e-grid .e-headercelldiv {
  font-size: 14px;
  line-height: 21px;
  font-family: "MyriadPro", sans-serif;
  color: #747876;
  font-weight: 400;
  display: flex;
  align-items: center;
}

#logsErros .e-toolbar .e-toolbar-items div {
  margin-left: 20px;
  background-color: #f7f9f9;
}

#logsErros .e-toolbar .e-toolbar-items {
  background: #fff;
}

#logsErros #logsErros_toolbarItems {
  background-color: #fff;
  padding: 11px 0;
  border: 1px solid #e0e0e0;
}

#logsErros .e-rowcell {
  padding-top: 15px;
  padding-bottom: 15px;
}

#logsErros .active {
  font-size: 13px;
  line-height: 15px;
  font-family: "MyriadPro", sans-serif;
  font-weight: 400;
  padding: 7px 34px;
  border-radius: 2px;
}

#logsErros .active.no {
  background-color: #f6d6d2;
  color: #d20340;
}

#logsErros .active.yes {
  background-color: #e9f2da;
  color: #69be28;
}

#logsErros .e-row {
  cursor: unset;
}

#logsErros
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-item
  .e-tbar-btn.e-btn.e-tbtn-txt
  .e-icons.e-btn-icon,
#logsErros .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  color: #007e7a;
}

#logsErros .e-headercontent .e-headercell {
  background-color: #f7f9f9;
}

#logsErros .e-gridcontent {
  border: 1px solid #e0e0e0;
}

#logsErros a {
  color: #007e7a;
  cursor: pointer;
}
