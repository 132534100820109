.tempItem {
    background-color: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    margin-bottom: 25px;
}

.tempItem .top {
    padding: 18px;
    border-bottom: 2px solid #F7F9F9;
}

.tempItem .top .mdl2 {
    color: #007E7A;
    font-size: 18px;
}

.tempItem .top h2 {
    color: #007E7A;
    font-family: 'Caecilia';
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 15px;
}

.tempItem .content {
    padding: 20px 18px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.tempItem .content .input {
    width: 300px;
    min-height: 90px;
}

.tempItem .content .combo {
    width: 220px;
    min-height: 90px;
}

.tempItem .content .divButton button {
    float: right;
    border: 1px solid #007E7A;
    color: #007E7A;
    padding: 10px 28px;
    background-color: #fff;
    box-shadow: none;
}

.tempItem .content .submit {
    width: 140px;
}

.tempItem .content .input label,
.tempItem .content .combo label,
.tempItem .content .submit label {
    color: #747876;
    font-family: "MyriadPro";
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
}

.tempItem .table {
    margin-bottom: 0;
}

.tempItem .table .e-grid {
    border: none;
}

.tempItem .table .e-grid .e-headercell {
    background-color: #F7F9F9;
    padding: 18px 21px;
}

.tempItem .table .e-grid .e-headercelldiv {
    height: auto;
}
.tempItem .e-grid.e-device .e-headercell:first-child,
.tempItem .e-grid.e-device .e-headercell:last-child {
    padding: 18px 21px;
}

.tempItem .table .e-grid .e-headercelldiv span {
    color: #747876;
    font-size: 14px;
    line-height: 17px;
    font-family: "MyriadPro";
    font-weight: 500;
}

.tempItem .table .e-gridcontent.e-lib.e-droppable {
    border: none;
}

.tempItem .combo .e-input-group span.e-input-group-icon.e-ddt-icon.e-icons{
    background-color: #F7F9F9;
}

.tempItem .table .e-gridcontent {
    border-width: 1px 0 0 0!important;
}

.tempItem #gridcomp .e-gridpager.e-control.e-pager.e-lib {
    border-width: 1px 0 0;
}

.tempItem .table .e-gridpager.e-control.e-pager.e-lib {
    border-width: 0px 0 0 0;
}

.span_area {
    background: #F7F9F9;
    padding: 5px;
    border-radius: 5px;
    margin:0 2px;
}

@media screen and (max-width: 500px) {
    .tempItem .content .input,
    .tempItem .content .combo {
        width: 100%;
    }
}