/*-------- AdvertisingContent CSS --------*/
#advertisingGrid {
    border: none;
}

#advertisingGrid .e-gridfooter {
    border: 1px solid #e0e0e0;
    border-top: none;
}

#advertisingGrid .e-gridcontent {
    border: 1px solid #e0e0e0;
}

#advertisingGrid .footerContent {
    font-family: "MyriadPro";
    font-size: 14px;
    color: #747876;
    display: flex;
    flex-direction: column;
}

#advertisingGrid .footerContent .footerLabel {
    font-weight: 600;
}


#advertisingGrid .footerContent .footerTotal {
    color: #69be28;
}
#advertisingGrid .footerContent .footerTotal.red {
    color: red;
}

.e-grid .e-ungroupbutton.e-icon-hide::before {
    color: black;
}
#advertisingGrid .nomeTemplate {
    color: #007e7a;
    cursor: pointer;
}

#advertisingGrid .contrato {
    color: #007e7a;
}

#advertisingGrid .editItems {
    display: flex;
    justify-content: space-between;
}

#advertisingGrid .editItems span {
    cursor: pointer;
}

#advertisingGrid .sumClass {
    font-family: "MyriadPro";
    font-size: 14px;
    color: #747876;
    font-weight: 600;
}
.flex{
    display: flex;
    flex-wrap: wrap;
}
/* -------- Content Item CSS -------- */

.divider{
    margin-top: 20px;
    margin-bottom: 15px;
    width: calc(100% + 48px);
    left: -24px;
    position: relative;
    border-bottom: 2px solid #F7F9F9;
}

.error {
    color: #d20340;
}

#FormAdvertisingBudget > div {
    margin-bottom: 20px;
}

#FormAdvertisingBudget .e-gridcontent {
    border: 1px solid #e0e0e0;
}

/* --------- Data Content CSS --------- */

#FormAdvertisingBudget .topContent .mdl2.mdl2-data {
    font-size: 18px;
    color: #007e7a;
}

#FormAdvertisingBudget .innerContentAdvertising .topInput .e-float-input {
    margin: 0;
}

#FormAdvertisingBudget .innerContentAdvertising .inputsRow {
    display: flex;
    justify-content: space-between;
    padding: 20px 23px;
    flex-wrap: wrap;
}

#FormAdvertisingBudget .innerContentAdvertising .inputsRow:nth-child(even) {
    background-color: #fff;
}

#FormAdvertisingBudget .innerContentAdvertising .inputsRow .input {
    width: 285px;
}

#FormAdvertisingBudget .innerContentAdvertising .topInput {
    padding: 20px 23px 0;
    background-color: #fff;
}

#FormAdvertisingBudget .innerContentAdvertising {
    border: 1px solid #e0e0e0;
}

#FormAdvertisingBudget .innerContentAdvertising .inputsRow .input .e-float-input span {
    background-color: #f7f9f9;
}

#FormAdvertisingBudget .innerContentAdvertising .inputsRow:nth-child(3) .input .e-float-input span {
    background-color: #fff;
}

#FormAdvertisingBudget .innerContentAdvertising .inputsRow .CheckBoxDiv {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

#FormAdvertisingBudget .innerContentAdvertising .inputsRow .CheckBoxDiv span {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    padding-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#FormAdvertisingBudget .innerContentAdvertising .inputsRow .CheckBoxDiv span::before {
    margin-right: 9px;
}

#FormAdvertisingBudget span:not(.e-check,.status){
    padding-left: 5px;
}

/* -------- File Content CSS --------*/

#AdvertisingFileDiv .status {
    padding: 6px 19px;
    width: 100%;
    font-family: "MyriadPro";
    font-size: 12px;
    display: flex;
    justify-content: center;
}

#AdvertisingFileDiv .status.aguardando {
    color: #ee7518;
    background-color: #fee7d2;
}

#AdvertisingFileDiv .status.aprovado {
    color: #69be28;
    background-color: #e9f2da;
}
#AdvertisingFileDiv .status.reprovado {
    color: #bb133e;
    background-color: #f6d6d2;
    opacity: 1;
}

/* -------- Historic Content CSS --------*/

#AdvertisingHistoricDiv {
    margin-top: 20px;
}

#AdvertisingHistoricDiv .topContent .mdl2.mdl2-historic {
    font-size: 18px;
    color: #007e7a;
}

#modalEditAnnual .upload {
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    padding: 9px 8px;
    position: relative;
}

#modalEditAnnual .upload.error {
    border: 1px solid #d20340;
}

#modalEditAnnual .upload > div {
    border: 1px dotted #d8cdcd;
    border-radius: 4px;
}

#modalEditAnnual .upload > div.new {
    background-color: #f7f9f9;
    text-align: center;
    height: 245px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#modalEditAnnual .upload > div img {
    width: 100%;
}

#modalEditAnnual .upload > div p {
    color: #747876;
    font-size: 16px;
    line-height: 19px;
    font-family: "MyriadPro";
    font-weight: 400;
    margin: 35px 0 0;
}

#modalEditAnnual .upload > div p span {
    cursor: pointer;
    color: #007e7a;
}

#modalEditAnnual .upload > div span#change {
    cursor: pointer;
    display: block;
}

#loadingItem .e-spinner-pane.e-spin-show {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: #dedede80;
}

#btnNew {
    width: auto;
    padding: 0px 20px 0px 10px;
    height: 31px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F7F9F9;
    color: #007e7a; 
    font-size: 14px;
}

#btnNew:hover,#btnNew:focus{
    border: none;
    outline: none;
}

#btnNew span.mdl2 {
    font-size: 10px;
    font-weight: 600;
}

.pr-10{
    padding-right: 10px;
}

#anexos-form .e-file-select-wrap{
    display: none !important;
}

#loading2 .e-spinner-pane.e-spin-show {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: #dedede80;
}

@media screen and (max-width: 670px) {
    #FormAdvertisingBudget .innerContentAdvertising .inputsRow .input {
        width: 100%;
    }
}