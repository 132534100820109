.profileTop {
    width: 100%;
    height: 125px;
    background-image: url('../../css/imgs/profile_top.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

/**/

.userData {
    background-color: #FFFFFF;
    border: 1px solid #ECECEC;
    border-top: 6px solid #F1F1F1;
    border-radius: 4px;
    padding: 0 18px 25px;
    margin-bottom: 30px;
    margin-top: -16px;
}

.userData .textAreaContainer textarea {
    resize: none !important;
}

.userData .top {
    background-color: #F7F9F9;
    border: 1px solid #F1F1F1;
    text-align: center;
    padding: 0 23px 23px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.userData .top .img {
    background-color: #F7F9F9;
    padding: 10px;
    border: 6px solid #FFF;
    border-radius: 50%;
    display: inline-flex;
    margin: 0 0 20px;
    margin-top: -74px;
    position: relative;
    cursor: pointer;
    width: 128px;
    height: 128px;
    overflow: hidden;
    background: url('../../css/imgs/user_blank.png') no-repeat #fff center;
}

.userData .top .img.disabled {
    cursor: not-allowed;
}

.userData .top .img::before {
    content: "";
    position: absolute;
    top: -7px;
    left: -7px;
    right: -7px;
    bottom: -7px;
    border: 1px solid #ECECEC;
    border-radius: 50%;
    z-index: 1;
}

.userData .top .img img {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    height: 100%;
}

.userData .top .userName .e-input-group.e-control-wrapper textarea.e-input {
    color: #007E7A;
    font-family: 'Caecilia' !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    text-align: center;
    white-space: nowrap;
}

.userData .top .userName .e-input-group.e-control-wrapper {
    border: none;
}

.userData .top .checkItem .e-wrapper {
    margin-top: 13px;
    margin-right: 5px;
}

.userData .top .checkItem .e-wrapper .e-icons {
    height: 14px;
    width: 14px;
}

.userData .top .checkItem .e-wrapper .e-icons.e-check::before {
    display: block;
    margin-top: -1px;
    font-size: 11px;
}

.userData .top .checkItem .e-wrapper .e-label {
    font-size: 14px;
    line-height: 17px;
    color: #747876;
}

.userData > div {
    margin-top: 23px;
}

.userData > div > div:not(:last-child) {
    margin-bottom: 28px;
}


.userData > div > div .mdl2 {
    color: #007E7A;
    font-size: 18px;
}

.userData > div > div .input {
    padding-left: 12px;
    margin-left: 12px;
    border-left: 1px solid #ECECEC;
    width: calc(100% - 42px);
}

.userData > div > div .input .e-input-group:not(.e-float-icon-left),
.userData > div > div .input .e-input-group.e-control-wrapper:not(.e-float-icon-left) {
    border: none;
}

.userData .itemPhone {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    width: 100%;
}

.userData .addLine {
    cursor: pointer;
    /*display: flex;
    align-items: flex-start;*/
}

.userData div:not(.itemPhone) .addLine {
    float: right;
}

.userData .addLine .mdl2 {
    font-size: 10px;
    background: #F7F9F9;
    line-height: 28px;
    width: 28px;
    border-radius: 4px;
    text-align: center;
}

.userData #phone {
  position: relative;
  padding: 9px 0 9px 2.3rem;
}

.userData .countryAndDropDown {
  position: absolute;
}

.userData .dropDownList {
  width: 100%;
  height: 5rem;
  position: relative;
  top: .5rem;
  overflow-y: scroll;
  background: #fff;
  border: 1px solid #ececec;
  margin: 0;
  z-index: 1;
  border-radius: 4px;
}

.userData .containerCountry {
  width: min-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* position: absolute; */
  margin-top: .7rem;
  /* bottom: 2.2rem; */
  z-index: 1;
}

.userData .flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  margin: 0;
  border-radius: 0;
  background: #fff;
}

.userData .flex:hover {
  background: #E2E2E2;
}

.userData .countryFlag {
  width: 22px;
}

.userData .countryName {
  margin: 0 0 0 6px;
}

.userData .containerCountry .countryIcon {
  font-size: 10px;
}

/**/

.profData {
    background-color: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    margin-top: -16px;
    margin-bottom: 30px;
}

.profData .top {
    padding: 18px;
    border-bottom: 2px solid #F7F9F9;
}

.profData .top h2 {
    color: #007E7A;
    font-family: 'Caecilia';
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 15px;
}

.profData .top .mdl2 {
    color: #007E7A;
    font-size: 18px;
}

.profData .checkItem {
    display: flex;
    flex-direction: row-reverse;
}

.profData .checkItem .e-wrapper {
    margin-top: 13px;
}

.profData .checkItem .e-wrapper .e-icons {
    height: 14px;
    width: 14px;
}

.profData .checkItem .e-wrapper .e-icons.e-check::before {
    display: block;
    margin-top: -1px;
    font-size: 11px;
}

.profData .checkItem .e-wrapper .e-label {
    font-size: 14px;
    line-height: 17px;
    color: #747876;
}


.profData .one {
    padding: 20px 18px;
}

.profData .three {
    padding: 20px 18px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.profData .three .input {
    /*max-width: 180px;*/
    width: calc(100%/3 - 40px/3);
}

.profData .two {
    padding: 20px 18px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.profData .two .input {
    width: calc(50% - 10px);
    min-height: 100px;
}

.profData .two .input:nth-child(2n) {
    margin-left: 10px;
}

.profData .two .input:nth-child(2n - 1) {
    margin-right: 10px;
}


/**/
.changePassword {
    background-color: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    margin-bottom: 30px;
}

.changePassword .top {
    padding: 18px;
    border-bottom: 2px solid #F7F9F9;
}

.changePassword .top h2 {
    color: #007E7A;
    font-family: 'Caecilia';
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 15px;
}

.changePassword .top .mdl2 {
    color: #007E7A;
    font-size: 18px;
}

.changePassword .content {
    padding: 20px 18px 13px;
}

.changePassword .content .input {
    margin-bottom: 20px;
}

.changePassword .content .input label {
    color: #747876;
    font-family: "MyriadPro";
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
}

.whoIswho {
    background-color: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    margin-bottom: 30px;
}

.whoIswho .mdl2.mdl2-hierarchy {
    color: #007E7A;
}

.whoIswho .top {
    padding: 18px;
    border-bottom: 2px solid #F7F9F9;
}

.whoIswho .top h2 {
    color: #007E7A;
    font-family: 'Caecilia';
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 15px;
}

.whoIswho .mainNode {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #FDFDFD;
    border: 1px solid #ececec;
    border-radius: 4px;
    width: 285px;
    height: 80px;
    position: relative;
}

.whoIswho .mainNode .shieldIcon {
    position: absolute;
    right: 15px;
    bottom: 15px;
    width: 16px;
    height: 16px;
    background-image: url('../../css/imgs/shield_icon.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.whoIswho .mainNode img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.whoIswho .mainNode .innerNode {
    display:flex;
    flex-direction:column;
}

.whoIswho .mainNode .innerNode .name {
    color: #007E7A;
    font-family: 'Caecilia';
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 5px;
}

.whoIswho .mainNode .innerNode .role {
    font-family: 'Arial';
    color: #747876;
    font-size: 12px;
}

.whoIswho .mainNode .innerNode .role2 {
    opacity: 0.7;
    font-family: 'Arial';
    color: #747876;
    font-size: 12px;
}

.whoIswho .content {
    padding: 17px 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.whoIswho .content .vertical.connector {
    border: 1px solid #ECECEC;
    height: 24px;
    width: 0;
}

.whoIswho .content .Diretores {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.whoIswho .content .bottomWho {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: 1px solid #ECECEC;
    padding-top: 20px;
}

.whoIswho .content .Subordinados {
    margin-bottom: 17px;
}

.whoIswho .content .userWho {
    border-bottom: 4px solid #EDB111;
    border-radius: 0px 0px 4px 4px;
}

/*Users Fim*/

/* ModalProfile */

#modalProfile > div:first-child button {
    position: absolute;
    right: -21px;
    left: inherit;
    top: -41px;
    margin: 25px 5px 20px 20px;
}

#modalProfile .upload {
    border: 1px solid #F1F1F1;
    border-radius: 4px;
    padding: 9px 8px;
}

#modalProfile .upload > div {
    border: 1px dotted #D8CDCD;
    border-radius: 4px;
}

#modalProfile .upload > div.new {
    background-color: #F7F9F9;
    text-align: center;
    height: 245px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#modalProfile .upload > div img {
    width: 100%;
}

#modalProfile .upload > div p {
    color: #747876;
    font-size: 16px;
    line-height: 19px;
    font-family: 'MyriadPro';
    font-weight: 400;
    margin: 35px 0 0;
}

#modalProfile .upload > div p span {
    cursor: pointer;
    color: #007E7A;
}

#modalProfile .upload > div span#change {
    cursor: pointer;
    display: block;
}

#modalProfile .hidden {
    display: none;
}

#modalProfile .e-upload {
    border: none;
}

#modalProfile .e-upload .e-file-select-wrap {
    display: none;
}

#modalProfile .e-upload .e-upload-files {
    background-color: #F7F9F9;
    border: 1px solid #F1F1F1;
}

#modalProfile .e-btn.e-flat.e-primary {
    width: 197px;
    color: #ffffff;
    background-color: #007e7a;
    margin: 25px 5px 20px 20px;
}
/* ModalProfile Fim*/

.changePassword form .msgs {
    padding: 0 23px 23px 17px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

.changePassword form .msgs .msgError{
    color: #BB133E;
}

.changePassword form .msgs .msgSucess {
    color: #007e7a;
}

#loadingProfile {
    height: 80px;
    position: relative;
}

@media screen and (max-width: 500px) {
    .profData .three .input,
    .profData .two .input {
        width: 100%;
    }
    .profData .two .input:nth-child(2n),
    .profData .two .input:nth-child(2n - 1) {
        margin-left: 0;
        margin-right: 0;
    }
    .profData .checkItem {
        flex-direction: row;
        margin-bottom: 30px;
    }
}
