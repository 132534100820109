.filtroSuppliers {
    justify-content: space-around;
}

.filtroSuppliers .btnBusca .btnFilter .e-btn.e-outline.e-primary,
.filtroSuppliers .btnBusca .btnFilter .e-css.e-btn.e-outline.e-primary {
    margin: 19px 0 19px 15px !important;
    border-color: transparent !important;
    width: 89px !important;
}

.filtroSuppliers .btnBusca .btnFilter.active .e-btn.e-outline.e-primary,
.filtroSuppliers .btnBusca .btnFilter.active .e-css.e-btn.e-outline.e-primary {
    border-color: #007e7a !important;
}

.filtroSuppliers .btnBusca .btnSearch button.e-control.e-btn.e-lib.e-primary.search {
    margin: 19px 0 19px 15px !important;
    width: 89px !important;
}

.listSuppliers .e-grid.e-default tr th.e-headercell:first-child:not(.e-firstcell),
.listSuppliers th.e-headercell.e-fltr-icon.e-mousepointer {
    background-color: #f7f9f9;
}

.supItem {
    background-color: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    margin-bottom: 25px;
}

.supItem .top {
    padding: 18px;
    border-bottom: 2px solid #F7F9F9;
}

.supItem .top .mdl2 {
    color: #007E7A;
    font-size: 18px;
}

.supItem .top h2 {
    color: #007E7A;
    font-family: 'Caecilia';
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 15px;
}

.supItem .content > div {
    padding: 20px 18px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.supItem .content > div label {
    color: #747876;
    font-family: "MyriadPro";
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
}

.supItem .content > div.firstLine .input {
    width: 58%;
}

.supItem .content > div.firstLine .input input {
    font-size: 18px;
}

.supItem .content > div.firstLine .combo,
.supItem .content > div.firstLine .number {
    width: 18%;
}

.supItem .content > div.secondLine > div {
    width: 48%;
}

.supItem .content > div.thirdLine > div {
    width: 23%;
}

.supItem .content > div.fourthLine > div {
    width: 100%;
}

#modalSuppliers > div:first-child button {
    position: absolute;
    right: -21px;
    left: inherit;
    top: -41px;
}

#modalSuppliers .upload {
    border: 1px solid #F1F1F1;
    border-radius: 4px;
    padding: 9px 8px;
}

#modalSuppliers .upload > div {
    border: 1px dotted #D8CDCD;
    border-radius: 4px;
}

#modalSuppliers .upload > div.new {
    background-color: #F7F9F9;
    text-align: center;
    height: 245px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#modalSuppliers .upload > div img {
    width: 100%;
}

#modalSuppliers .upload > div p {
    color: #747876;
    font-size: 16px;
    line-height: 19px;
    font-family: 'MyriadPro';
    font-weight: 400;
    margin: 35px 0 0;
}

#modalSuppliers .upload > div p span {
    cursor: pointer;
    color: #007E7A;
}

#modalSuppliers .upload > div span#change {
    cursor: pointer;
    display: block;
}

#modalSuppliers .e-upload {
    border: none;
}

#modalSuppliers .e-upload .e-file-select-wrap {
    display: none;
}

#modalSuppliers .e-upload .e-upload-files {
    background-color: #F7F9F9;
    border: 1px solid #F1F1F1;
}

#modalSuppliers .e-btn.e-flat.e-primary {
    width: 197px;
    color: #ffffff;
    background-color: #007e7a;
}

.e-error{ color:#ff0000!important; font-weight:100!important; }

.loadSuppliersList {
    margin-top: 60px;
}

@media screen and (max-width: 600px) {
    .supItem .content > div.firstLine .input,
    .supItem .content > div.firstLine .combo, 
    .supItem .content > div.firstLine .number {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .supItem .content > div.secondLine > div,
    .supItem .content > div.thirdLine > div {
        width: 100%;
    }
}