.home {
    margin-top: 54px;
}

/* UserHome CSS*/

.mainHome {
    border: 1px solid #ECECEC;
    background-color: #fff;
    border-radius: 4px;
    min-height: 240px;
    margin: 0 0 30px;
}

.mainHome .perfil {
    display: flex;
    align-items: center;
    padding: 27px;
    padding-left: 0;
}

.mainHome .perfil >div:first-child {
    border-left: 4px solid #EDB111;
    display: flex;
    align-items: center;
}

.mainHome .perfil >div img {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin-left: 20px;
    border: 1px;
}

.mainHome .perfil .name {
    margin-left: 20px;
}

.mainHome .perfil .name h3 {
    color: #747876;
    margin: 0 0 4px;
    font-family: 'Caecilia';
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
}

.mainHome .perfil .name h4 {
    color: #007E7A;
    font-family: 'Caecilia';
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 4px 0 0;
}

.divTab .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap{
    text-align: center;
}

.divTab .e-tab .e-tab-header .e-indicator {
    display: none !important;
}

.divTab .e-tab .e-tab-header:not(.e-vertical) .e-toolbar-item.e-active,
.divTab .e-tab .e-content {
    background-color: #fff !important;
}

.divTab .e-tab .e-tab-header .e-toolbar-item .e-tab-text {
    font-family: 'MyriadPro' !important;
    font-size: 13px !important;
    text-transform: none !important;
    font-weight: 400 !important;
    align-items: center;
}

.divTab .e-tab .e-tab-header .e-toolbar-item {
    height: 45px !important;
}

.divTab .e-toolbar.e-control[class*='e-toolbar'] {
    width: auto !important;
    margin: 0 4px;
    padding: 10px 15px 0;
}

.divTab .e-toolbar.e-control {
    background-color: rgba(181, 193, 193, 0.13) !important;
}

.divTab {
    margin-top: 10px;
}

.divTab .e-tab .e-tab-header:not(.e-vertical) .e-toolbar-item.e-active {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
}

.divTab .e-tab .e-tab-header .e-toolbar-items,
.divTab .e-tab .e-tab-header .e-toolbar-item {
    width: 100% !important;
}

.divTab .e-toolbar .e-toolbar-items {
    display: flex !important;
    justify-content: space-between;
}


.divTab .e-tab .e-tab-header .e-toolbar-items {
    background: initial !important;
}

.menuHome hr {
    width: 90%;
    border-top: 1px solid #EDEEEE;
    height: 0;
}

.menuHome .mdl2 {
    margin-right: 15px;
    font-size: 18px;
}

.divTab .mdl2 {
    margin-right: 15px;
    font-size: 18px;
}


.menuHome >div {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 0 0 0 50px;
}

.menuHome div span {
    color: #007E7A!important;
}

.menuHome div h5 {
    margin: 3px 0 !important;
    opacity: 0.75;
    font-size: 13px;
    font-weight: 500;
    color: #747876 !important;
}

.menuHome div h4 {
    opacity: 1 !important; 
    font-size: 13px;
    font-weight: 500;
    color: #747876 !important;
}

.menuHome div h4 span {
    opacity: 1 !important;
    color: #747876 !important;
    padding: 0;
}

.menuHome div h4 span input {
    opacity: 1 !important;
    color: #747876 !important;
    cursor: auto !important;
    padding: 0;
}

.menuHome a {
    color: #007E7A !important;
    cursor: pointer;
    text-align: center;
    display: block;
    margin: 15px 0;
}

.menuHome div .phoneStyle{
    display: flex;
    flex-direction: column;
}

.menuHome div .phoneStyle h4 > .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-disabled,
.menuHome div .phoneStyle h4 > .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-disabled {
    background: none;
}

.divTab .whoIswhoUsers {
    padding: 10px 12px 0 12px;
}

.divTab .whoIswhoUsers .e-input-group {
    padding: 6px;
}

.divTab .whoIswhoUsers .mdl2.mdl2-search {
    font-size: 13px;
    color: #007E7A;
    font-weight: 400;
}

.divTab .whoIswhoUsers .showWhoUsers {
    margin-top: 10px;
}

.divTab .whoIswhoUsers .seeMore {
    display: flex;
    justify-content: center;
}

.divTab .whoIswhoUsers .seeMore label {
    color: #007E7A;
    font-size: 13px;
    margin: 20px 0;
    font-family: 'MyriadPro';
    cursor: pointer;
}

.divTab .whoIswhoUsers .showWhoUsers .whoUser {
    display: flex;
    padding: 10px 24px;
}

.divTab .whoIswhoUsers .showWhoUsers .whoUser img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.divTab .whoIswhoUsers .showWhoUsers .whoUser label {
    font-family: 'MyriadPro';
    color: #007E7A;
    font-size: 14px;
    padding-left: 13px;
    margin: 0;
    cursor: pointer;
}

.divTab .whoIswhoUsers .showWhoUsers .whoUser:nth-child(odd) {
    background-color: #F6F7F7;
}

/* BannerHome CSS*/
.boxBannerHome {
    border-radius: 4px;
    margin: 0 0 30px;
    overflow: hidden;
}

.boxBannerHome .carousel-indicators {
    justify-content: flex-end;
    margin: 0 17px 24px auto;
    list-style: none;
    pointer-events: none;
    width: calc(100% - 34px);
}

.boxBannerHome .carousel-indicators li {
    top: -26px;
    height: 10px;
    width: 10px;
    border: none;
    border-radius: 50%;
    margin: 0 10px 0;
    pointer-events: auto;
}

.boxBannerHome .carousel-indicators .active {
    background-color: #F1C040;
}

.boxBannerHome .carousel-inner {
    padding-bottom: 40%;
}

.boxBannerHome.carousel-control-next,
.boxBannerHome .carousel-control-prev {
    display: none;
}

.boxBannerHome .carousel-caption {
    padding: 0;
    left: 30px;
    bottom: 20px;
    right: 140px;
    pointer-events: none;
    text-align: left;
}

.boxBannerHome .carousel-caption h3 {
    display: inline-block;
    background-color: #EDB111;
    border-radius: 4px;
    font-family: 'MyriadPro';
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    padding: 5px 15px;
    margin: 0 0 14px;
}

.boxBannerHome .carousel-caption p {
    font-family: 'Caecilia';
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 7px;
}

.boxBannerHome .carousel-item {
    height: 100%;
    width: 100%;
    position: absolute;
}

.boxBannerHome .carousel-item img {
    min-width: 100%;
    min-height: 100%;
    height: 100%;
}

.boxBannerHome .carousel-item:before {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent linear-gradient(358deg, #000000 0%, #00000000 100%) 0% 0% no-repeat padding-box;
    opacity: 0.6;
    position: absolute;
    z-index: 1;
    pointer-events: none;
}

/* Meus Documentos CSS */

.control-section .e-filemanager .e-fe-folder {
    background-image: url("../../css/imgs/folder.svg")!important;
}

.control-section span.e-headertext {
    font-weight: 600;
}

.control-section .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
    color: #00737A;
}

.control-section .e-splitter.e-splitter-horizontal .e-pane.e-pane-horizontal, .e-splitter.e-splitter-vertical .e-pane.e-pane-horizontal {
    order: initial !important;
    flex-basis: 40% !important;
    background-color: #fff;
}

.control-section .e-layout-content.e-pane.e-pane-horizontal.e-scrollable.e-resizable {
    overflow: hidden !important;
}

.control-section .e-filemanager .e-address .e-list-text,
.control-section .e-treeview .e-list-text,
.control-section .e-grid .e-rowcell {
    font-size: 12px;
    color: #747876 !important;
}

.control-section .e-filemanager {
    border: 1px solid #ECECEC;
}

.control-section .e-toolbar .e-toolbar-items.e-tbar-pos {
    border-right: 1px solid #ECECEC;
    border-left: 1px solid #ECECEC;
}

.controle-section .e-toolbar-item button span::before {
    color: #007E7A;
}

.controle-section thread {
    border-right: 1px solid #ECECEC;
    border-left: 1px solid #ECECEC;
}

.e-grid.e-default tr td:first-child,
.e-grid.e-default tr th.e-headercell:first-child:not(.e-firstcell),
.e-grid.e-default tr th.e-detailheadercell:first-child,
.e-grid.e-default tr th.e-filterbarcell:first-child {
    border-left: 1px solid #ECECEC;
}

.e-filemanager .e-fe-newfolder::before, .controle-section .e-fe-popup .e-fe-newfolder::before,
.e-filemanager .e-fe-upload::before, .e-fe-popup .e-fe-upload::before,
.e-filemanager .e-fe-sort::before, .e-fe-popup .e-fe-sort::before,
.e-filemanager .e-fe-refresh::before, .e-fe-popup .e-fe-refresh::before,
.e-filemanager .e-address .e-address-list-item .e-icons::before {
    color: #007E7A;
    padding: 0;
}

.documentos {
    border-radius: 4px;
    overflow: hidden;
    background-color: #ffffff;
    border: 1px solid #ECECEC;
    margin: 0 0 30px;
}

.cabecalho {
    display: flex;
    color: #007E7A;
    height: 52px;
    text-align: left;
    align-items: center;
    border-bottom: none;
}

.cabecalho span{
    font-weight: 600;
    font-family: 'Caecilia';
    font-size: 16px;
}

.cabecalho p{
    margin: 0;
    padding: 0 20px;
    font-size: 18px;
}


@media screen and (max-width: 768px) {
    .boxBannerHome .carousel-item:before {
        background: #007E7A;
        opacity: 0.88;
    }
    .boxBannerHome .carousel-inner {
        padding-bottom: 45%;
    }
    .boxBannerHome .carousel-caption {
        display: block !important;
        left: 10px;
        right: 25px;
        bottom: 30px;
        max-height: 94px;
        overflow: hidden;
    }
    .boxBannerHome .carousel-caption h3 {
        left: 10px;
        right: 25px;
        border: 30px;
    }
    .boxBannerHome .carousel-caption p {
        font-size: 16px;
        line-height: 18px;
    }
    .boxBannerHome .carousel-indicators {
        justify-content: flex-start;
        margin: 0 10px 18px;
    }
    .boxBannerHome .carousel-indicators li {
        width: 6px;
        height: 6px;
        margin: 0 5px 0;
    }
}

/* -------- WhoIsWho Home CSS -------- */

#WhoIsWhoHome {
    margin-bottom: 30px;
}

#WhoIsWhoHome .whoMain {
    display: flex;
    margin-top: 20px;
    padding-right: 25px;
    padding-left: 25px;
    position: relative;
    scroll-behavior: smooth;
}

#WhoIsWhoHome .whoTitle {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

#WhoIsWhoHome .mdl2.mdl2-hierarchy {
    font-size: 16px;
    color: #007E7A;
    margin-left: 25px;
}

#WhoIsWhoHome h2 {
    font-family: 'Caecilia';
    font-size: 16px;
    color: #007E7A;
    margin: 0 0 0 8px;
    font-weight: 600;
}

#WhoIsWhoHome .whoMap {
    display: flex;
    padding-top: 15px;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

#WhoIsWhoHome .whoMap .searchDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: #FFF;
}

#WhoIsWhoHome .whoMap .mdl2.mdl2-search {
    color: #007E7A;
    margin: auto;
    padding: 0;
    font-size: 26px;
    font-weight: 400;
}

#WhoIsWhoHome .whoMap .whoImgDiv {
}

#WhoIsWhoHome .whoMap .whoSearchDiv {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    cursor: pointer;
}

#WhoIsWhoHome .whoMap .whoImgDiv img {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    border: 1px;
}

#WhoIsWhoHome .whoMap label {
    font-size: 14px;
    color: #007E7A;
    text-align: center;
    padding-top: 10px;
    width: 100%;
    font-family: 'MyriadPro';
}

#WhoIsWhoHome .mdl2-arrowRight {
    color: #007E7A;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

#WhoIsWhoHome .mdl2-arrowLeft {
    color: #007E7A;
    font-size: 20px;
    text-align: center;
    position: absolute;
    font-weight: 600;
    left: 0;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
}

/* -------- Message Home CSS -------- */

#MessageHouseHome {
    display: flex;
    background-color: #fff;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    padding: 19px 24px;
}

#MessageHouseHome .innerMessage {
    display: flex;
    flex-direction: column;
    margin-left: 22px;
}

#MessageHouseHome .innerMessage span {
    font-family: 'Caecilia';
    color: #747876;
    font-size: 18px;
}

#MessageHouseHome .innerMessage label {
    font-family: 'MyriadPro';
    color: #007E7A;
    font-size: 14px;
    cursor: pointer;
}

#modalShare .success{
    color: #69BE28;
}

#modalShare .error {
    color: #d20340;
}

.overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.2);
}